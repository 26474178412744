import React from "react";
import { Link } from "react-router-dom";
import Layout from "components/AlpacaComponents/Layout";

const NotFoundPage = () => (
  <Layout disableAppMenu={true} showBrandInHeader={true}>
    <div className="text-center">
      <div className="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
        <h1 className="mb-4">
          <strong>Error 404</strong>
        </h1>
        <p className="mb-4">
          The page you are looking for does not exist or has been removed.
        </p>
        <Link to="/" className="btn">
          Go back to the main page
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
