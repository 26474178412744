import React from "react";

import Asterisk from "./asterisk";

import { Box, BoxProps, Text } from "@chakra-ui/react";

interface FieldProps extends BoxProps {
  label: React.ReactNode;
  description?: string;
  required?: boolean;
  error?: boolean;
  errorMsg?: string;
  children: React.ReactNode;
}

// wraps input field with label and asterisk if required
export const Field = ({
  label,
  description,
  required,
  error,
  errorMsg,
  children,
  ...more
}: FieldProps) => {
  return (
    <Box {...more} error={error ? "" : undefined}>
      <Text
        fontSize="md"
        fontWeight="500"
        mb={description ? "" : "xs"}
        d="flex"
        w="100%"
      >
        {label}&nbsp;
        <Asterisk required={required} />
        <Text
          flexGrow={1}
          textAlign="right"
          m="0"
          justifyContent="end"
          marginLeft="auto"
          color="red.60"
          fontWeight="500"
          fontSize=".9rem"
        >
          {error && errorMsg}
        </Text>
      </Text>
      {description && (
        <Text color="gray.60" fontSize="sm" mb="xs">
          {description}
        </Text>
      )}
      {children}
    </Box>
  );
};

export default Field;
