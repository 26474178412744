import { createAction, createReducer } from "redux-act";

const REDUCER = "userData";
const NS = `@@${REDUCER}/`;

const _setUserData = createAction(`${NS}SET_USER_DATA`);


// Export this reducer
const initialState = { loaded: false, data: {} };
export default createReducer(
  {
    [_setUserData]: (state, payload) => {
      return { ...payload };
    },
  },
  initialState
);
