import { createAction, createReducer } from "redux-act";
import { notification } from "antd";
import Auth from "@aws-amplify/auth";

import { push } from "connected-react-router";
import * as app from "../app";
import * as auth from "./common";
import * as cognitoActions from "reducers/auth/cognito";
import * as accountListActions from "reducers/auth/accountList";
import * as logout from "./logout";
import { createAccountIfNotExist } from "../../v2-deprecated/api/auth";
import { paciamLogin } from "src/v2-deprecated/api/auth/paciam";
import { eventTracker } from "src/utils/eventTracker";

export const REDUCER = "login";
const NS = `@@${REDUCER}/`;

const _setFrom = createAction(`${NS}SET_FROM`);
const _setHideLogin = createAction(`${NS}SET_HIDE_LOGIN`);
export const setFailedLogin = createAction(`${NS}SET_FAILED_LOGIN`);
const _setForgotPasswordVerification = createAction(
  `${NS}SET_FORGOT_PASSWORD_VERIFICATION`
);
export const setMFAVerificationRequired = createAction(
  `${NS}SET_MFA_VERIFICATION_REQUIRED`
);
export const setMFAVerificationError = createAction(
  `${NS}SET_MFA_VERIFICATION_ERROR`
);

export const resetHideLogin = () => (dispatch, getState) => {
  const state = getState();
  if (state.pendingTasks === 0 && state.app.isHideLogin) {
    dispatch(_setHideLogin(false));
  }
  return Promise.resolve();
};

/**
 * Logs a user in using Cognito.
 *
 * @param {string} username
 * @param {string} password
 * @param {Function} dispatch
 */
async function login(username, password, redirect, dispatch) {
  // To make sure we have only one cognito cookie at a time
  logout.clearCookieStorage();
  username = username.toLowerCase();

  try {
    const resp = await paciamLogin(username, password);

    if (resp.account_source !== "" && resp.account_source !== "brokerdash") {
      dispatch(app.deleteSubmitForm(REDUCER));
      dispatch(_setHideLogin(true));
      // invalidate accountList and cognito to force reload the data at AuthCheck
      dispatch(accountListActions._invalidate());
      dispatch(cognitoActions._invalidate());

      // a session token used by oauth authorization page to know if we were already logged in or not
      window.sessionStorage.setItem("oauth_login", "1");
      dispatch(push("/brokerage/dashboard/overview"));

      return true;
    }

    auth.initAmplify();
    const user = await Auth.signIn(username, password);

    if (user.challengeName === "SELECT_MFA_TYPE") {
      user.sendMFASelectionAnswer("SOFTWARE_TOKEN_MFA", () => {});
      dispatch(setMFAVerificationRequired({ id: "login", user }));
    } else {
      dispatch(completeChallengeAndRedirect({ user, username, redirect }));
      // check if v2 account needs to be created
      await createAccountIfNotExist(user);
    }
  } catch (e) {
    console.error(e);
    dispatch(app.deleteSubmitForm(REDUCER));
    if (e.code === "UserNotConfirmedException") {
      dispatch(auth.setAccountVerificationRequired({ id: "login", username }));
    } else {
      dispatch(setFailedLogin(e));
    }
  }

  dispatch(_setFrom(""));
}

/**
 * completeChallengeAndRedirect will complete the MFA challenge response
 * and redirect the aswer after a successful login.
 *
 * This is broken out of the Auth.signIn() in the login() above because
 * there could be the need to choose the MFA method (if multiple are enabled).
 * The Auth class apparently does not have this, it's older and on the CognitoUser
 * object which has a callback function argument.
 */
const completeChallengeAndRedirect = ({ user, username, redirect }) => (
  dispatch
) => {
  username = username.toLowerCase();
  // TODO: Redesign all of these forms/pages. It would be nice to show extra fields as needed on same form page.
  // Not sure the MFA one will be needed for now since MFA is off. Add it in later.
  // That basically leaves just the NEW_PASSWORD_REQUIRED one for now.
  if (
    user.challengeName === "SMS_MFA" ||
    user.challengeName === "SOFTWARE_TOKEN_MFA"
  ) {
    // Note: This is the full CognitoUser object, as it has methods needed by Amplify, not just the username string.
    dispatch(setMFAVerificationRequired({ id: "login", user }));
  } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    // dispatch(_newFuncThatShowsMoreFields())
    // TODO: same kinda thing as verifying the account/email. Show a different form.
    // This would allow admins to forcibly require password resets for users.
    // this.changeState('requireNewPassword', user);
  } else if (user.challengeName === "MFA_SETUP") {
    // This shouldn't be seen on login page. This means the MFA set up isn't complete yet.
    // Theoretically it could be if the user enabled TOTP MFA and then left then came back to
    // the login page. They still need to confirm their TOTP code.
    dispatch(setMFAVerificationRequired({ id: "login", user }));
  } else {
    // See if the user's account has been verified
    Auth.verifiedContact(user).then((data) => {
      // Set the user state (this is important for the app to use the data, but also helps verificaiton codes, etc.)
      // This entire data object that's returned has a lot of useful info not just the 'user' key within it.
      user = Object.assign(user, data);
      // Is the user verified? The verified object could contain various items to be verified. email key is one.
      // Presumably phone is another...But we're not using phone number login right now.
      user.userConfirmed = false;
      if (Object.keys(data.verified).length > 0) {
        user.userConfirmed = true;
      }

      if (user.userConfirmed) {
        // User logged in, send event to Segment to help track DAU/MAU
        eventTracker("Logged In", {
          category: "Account",
        });

        // Hide the login and redirect to the user's overview dashboard
        dispatch(app.deleteSubmitForm(REDUCER));
        dispatch(_setHideLogin(true));

        // Note: Can not redirect to '/' here.
        let requestedPath = undefined;
        const requestedPathVal = window.sessionStorage.getItem(
          "requested_path"
        );
        if (requestedPathVal !== "/" && requestedPathVal !== "/login") {
          requestedPath = requestedPathVal;
        }
        // invalidate accountList and cognito to force reload the data at AuthCheck
        dispatch(accountListActions._invalidate());
        dispatch(cognitoActions._invalidate());

        // a session token used by oauth authorization page to know if we were already logged in or not
        window.sessionStorage.setItem("oauth_login", "1");

        const destination = redirect || requestedPath || "/";
        if (destination) {
          dispatch(push(destination));
        }
        return true;
      } else {
        // So dispatch that action for the login reducer.
        // Like the signup form, the login form also has the fields necessary to verify the account.
        dispatch(app.deleteSubmitForm(REDUCER));
        dispatch(
          auth.setAccountVerificationRequired({ id: "login", username })
        );
      }
    });
  }
};

export const submit = ({ username, password, redirect }) => (dispatch) => {
  dispatch(app.addSubmitForm(REDUCER));
  login(username, password, redirect, dispatch);
};

export const verify = ({ username, code }) => (dispatch, getState) => {
  username = username.toLowerCase();
  const state = getState();

  if (!username || !code) {
    // This should flip the form back.
    dispatch(app.deleteSubmitForm(REDUCER));
    dispatch(
      auth.setAccountVerificationRequired({ id: "login", username: false })
    );
  } else {
    // TODO: Probably move the signup to in here too. I don't like these very specific reducer files calling back to app.
    // It makes no sense. Nothing else will do that. Unless there's something app provides that this doesn't, I don't see the point.
    // There's some actions... ie. signup() will call auth.setAccountVerificationRequired
    // But that could even be moved I suppose. OR exported. If app controls all of the state management from one point, great...
    // But we can at least call those actions rather than moving the guts and logic of each reducer into a central place.
    // Just good organization and separation. Maybe there's some other stateful things, but should be able to move a lot of this.

    // After retrieveing the confirmatieon code from the user
    Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    })
      .then(() => {
        // Track event to Segment that someone created and verified their account
        eventTracker("Verified Email", {
          category: "Account",
        });

        // I think this only comes back as "SUCCESS"
        // No need to set the user state now other than the verified part.
        dispatch(
          auth.setUserState({
            userState: {
              ...state.auth.userState,
              userConfirmed: true,
            },
          })
        );

        // Clears the form (deletes from state - note: delete keyword).
        dispatch(app.deleteSubmitForm(REDUCER));

        // Unlike the signing reducer, we can't just push /login here.
        // We're already at login. So clear out the verification requirement and that should flip the login form back.
        dispatch(
          // if for some reason there was a problem maybe display that?
          // need a resend code link
          auth.setAccountVerificationRequired({
            id: "login",
            username: false,
          })
        );
        // Ensure error is cleared
        dispatch(auth.setAccountVerificationError(false));
      })
      .catch((err) => {
        console.error(err);
        dispatch(auth.setAccountVerificationError(err));
        // if for some reason there was a problem maybe display that?
        // need a resend code link
      });
  }
};

/**
 * Requests a password reset because the user forgot it.
 * This will send a verification code to the user so they can reset their password.
 *
 * @param {string} username
 */
export const forgotPassword = (username) => (dispatch) => {
  // _setForgotPasswordVerification() wil lbe used either way here.
  // The error will send an object with a message to show.
  Auth.forgotPassword(username.toLowerCase())
    .then((data) => {
      dispatch(_setForgotPasswordVerification(data));
    })
    .catch((err) => {
      dispatch(_setForgotPasswordVerification(err));
    });
};

/**
 * Submits a MFA verification code to Cognito.
 * The MFA type is 'SMS_MFA' for now, though could be 'SMS_MFA' or 'SOFTWARE_TOKEN_MFA'
 *
 * @param {Object}    user: {CognitoUser} The Cognito user object
 *                    code: {string}      Verification code (numbers for SMS)
 *                 mfaType: {string}      The MFA type
 *                redirect: {string}      Optional redirect for login (same as login())
 */
export const MFAVerify = ({ user, code, mfaType = "SMS_MFA", redirect }) => (
  dispatch
) => {
  // After retrieveing the confirmatieon code from the user
  Auth.confirmSignIn(user, code, mfaType)
    .then(() => {
      // User logged in, send event to Segment to help track DAU/MAU
      eventTracker("Logged In", {
        category: "Account",
      });

      // Hide the login and redirect to the user's overview dashboard
      dispatch(_setHideLogin(true));

      // a session token used by oauth authorization page to know if we were already logged in or not
      window.sessionStorage.setItem("oauth_login", "1");

      // Note: Can not redirect to '/' here.
      let requestedPath = undefined;
      const requestedPathVal = window.sessionStorage.getItem("requested_path");
      if (requestedPathVal !== "/" && requestedPathVal !== "/login") {
        requestedPath = requestedPathVal;
      }

      // invalidate accountList and cognito to force reload the data at AuthCheck
      dispatch(accountListActions._invalidate());
      dispatch(cognitoActions._invalidate());

      if (
        !requestedPath &&
        window.localStorage.getItem("live-redirect") === "true"
      ) {
        dispatch(push("/brokerage/dashboard/overview"));
      } else {
        dispatch(
          push(redirect || requestedPath || "/paper/dashboard/overview")
        );
      }

      // Send a notification
      // TODO: Maybe make a random message or tip here
      notification.open({
        type: "success",
        message: "You have successfully logged in.",
        description: "Welcome to Alpaca!",
      });

      // MFA no longer required
      dispatch(setMFAVerificationRequired({ id: "login", user: false }));
      // Ensure any error is cleared
      dispatch(setMFAVerificationError(false));

      // The forms
      dispatch(app.deleteSubmitForm(REDUCER));

      return true;
    })
    .catch((err) => {
      console.error(err);
      // An error will be displayed to the user but, note there is no resend MFA code method
      dispatch(setMFAVerificationError(err));
    });
};

// Helper to clear out login forms and state, used for MFA verification failure
export const clearLoginForms = () => (dispatch) => {
  dispatch(app.deleteSubmitForm(REDUCER));
  dispatch(setMFAVerificationRequired({ id: "login", user: false }));
  dispatch(resetHideLogin());
};

const initialState = {
  MFAVerificationRequired: {},
  MFAVerificationError: false,
};
export default createReducer(
  {
    [_setFrom]: (state, from) => ({ ...state, from }),
    [_setHideLogin]: (state, isHideLogin) => ({ ...state, isHideLogin }),
    [setFailedLogin]: (state, param) => ({ ...state, failedLogin: param }),
    [_setForgotPasswordVerification]: (state, param) => ({
      ...state,
      forgotPasswordVerification: param,
    }),
    [setMFAVerificationRequired]: (state, param) => {
      // like invalid forms, this requires a form id and (otherwise default 'all' forms)
      // however the key value here will be whatever was passed
      const MFAVerificationRequired = param
        ? {
            ...state.submitForms,
            [param.id || "all"]: param,
          }
        : false;
      return { ...state, MFAVerificationRequired };
    },
    [setMFAVerificationError]: (state, param) => {
      return { ...state, MFAVerificationError: param };
    },
  },
  initialState
);
