import _ from "lodash";
import useGetAssets from "../../../api/hooks/useGetAssets";
import AmplitudeProvider from "src/AmplitudeProvider";

import {
  UINavbar,
  UIButton,
  UIIcon,
} from "../../../../../src/local_libraries/@alpacahq:ui-deprecated";

import {
  useGetAccount,
  useGetBillingOverview,
} from "src/v2-deprecated/api/hooks";

import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Path } from "../../../path";
import { Asset } from "../../../api/rest";
import { useFlag } from "@alpacahq/flag-service";
import { useQuery } from "react-query";
import { AssetLike } from "../../../routes/dashboard/trade";
import { useHistory } from "react-router";
import { Box, BoxProps } from "@chakra-ui/react";
import { getCryptoAssets } from "../../../api/rest/data";
import { isPaperOrOnboarding } from "src/v2-deprecated/utils";
import { SidebarState, UIContext, Icon } from "@alpacahq/ui";

const SEARCH_RESULTS_LIMIT = 10;

const BANKING_AND_ONBOARDING_PATHS = [
  Path.ROUTE_BANKING,
  Path.ROUTE_BANKING_DEPOSIT,
  Path.ROUTE_NEW_ACCOUNT,
];

// a map of assets sorted by first letter and then word groups for fast searching
type AssetMap = {
  // letter group
  [key: string]: {
    // word group
    [key: string]: Partial<Asset>;
  };
};

export const TopBar = (props: BoxProps & { product: string }) => {
  const v2 = useFlag("uix-v2");
  const history = useHistory();

  const [assets, setAssets] = useState<AssetLike[]>([]);

  const { sidebarState, setSidebarState } = useContext(UIContext);
  const { account, authed } = useGetAccount();
  const { billing } = useGetBillingOverview("billing", {
    enabled: authed,
  });

  const { data: crypto } = useQuery("crypto", getCryptoAssets);
  const { assets: equities } = useGetAssets("equities", {
    enabled: !!authed,
  });

  // normalize asset symbol and name for searching
  const normalize = useCallback(
    (value: string) =>
      String(value)
        .toUpperCase()
        .trim()
        .replace(/[^a-z\s-]/gi, ""),
    []
  );

  useEffect(() => {
    // filter out assets that are already in the list
    const filtered = (crypto || [])
      .concat(equities)
      .filter((asset) => !assets.some((a) => a.symbol === asset.symbol));

    // add new assets to the list
    if (filtered.length) {
      setAssets([...assets, ...filtered]);
    }
  }, [equities, crypto]);

  // memoize assets into index map for fast searching
  const assetMap = useMemo<AssetMap>(
    () =>
      assets
        .filter((asset) => asset.symbol)
        .map(({ symbol, name }) => ({ symbol, name }))
        .reduce((prev, next) => {
          const symbol = normalize(next.symbol);
          const name = normalize(next.name);
          const keyForSymbol = symbol[0];
          const keyForName = name[0];

          // create symbol character group
          if (!prev[keyForSymbol]) {
            prev[keyForSymbol] = {};
          }

          // create name character group
          if (!prev[keyForName]) {
            prev[keyForName] = {};
          }

          // add asset to symbol and name groups
          prev[keyForSymbol][symbol] = next;
          prev[keyForName][name] = next;

          return prev;
        }, {} as AssetMap),
    [assets]
  );

  const onSearch = useCallback(
    (query: string) => {
      // normalize query string for searching
      const normalized = normalize(query);

      // find and set match based on first letter
      const match = assetMap[normalized.charAt(0)];

      // if no match, set empty array
      if (!match) {
        return [];
      }

      // sort by symbol in descending alphabetic order
      const matched = Object.keys(match)
        .filter((key) => key.startsWith(normalized))
        .map((key) => match[key])
        .sort((a, b) => {
          const matchA = a.symbol?.startsWith(normalized);
          const matchB = b.symbol?.startsWith(normalized);

          // if both match, sort
          if (matchA && matchB) {
            return (a.symbol?.length || 0) - (b.symbol?.length || 0);
          }

          if (matchA) {
            // negative number means a comes first
            return -1;
          } else if (matchB) {
            // positive number means b comes first
            return 1;
          } else {
            // if neither match, return 0
            return 0;
          }
        });

      return (
        _.uniqBy(matched, (asset) => asset.symbol)
          // we only care about the first X results
          .slice(0, SEARCH_RESULTS_LIMIT)
          // filter out assets without symbol
          .filter(({ symbol }) => !!symbol)
          // map to suggestion object
          .map(
            // cast as string because we already filtered out assets without symbol or name
            ({ symbol, name }) => ({
              label: symbol as string,
              description: name || "no asset description found",
              href: Path.ROUTE_TRADE.replace(":symbol", symbol as string),
            }),
            []
          )
      );
    },
    [assetMap]
  );

  // handle the deposit or open account button click
  const onDepositOrOpenAccountClick = useCallback(() => {
    // track the event
    AmplitudeProvider.dispatch(
      props.product === "paper"
        ? "navbar_open_live_account_button_clicked"
        : "navbar_deposit_funds_button_clicked"
    );

    // if the account is paper or onboarding, go to the new account page
    if (props.product === "paper") {
      history.push(Path.ROUTE_NEW_ACCOUNT);
    } else {
      // if the account is not paper or onboarding, go to the deposit page
      useFlag("uix-v2")
        ? history.push(Path.ROUTE_BANKING_DEPOSIT)
        : history.push(Path.ROUTE_BANKING);
    }
  }, [history, props.product]);

  const onDataSubscriptionClick = useCallback(() => {
    history.push(
      (billing?.lifetimeSubCount || 0) > 0
        ? // if the user has a subscription, go to the subscription page
          Path.ROUTE_USER_SUBSCRIPTION
        : // if the user does not have a subscription, go to the new subscription page
          Path.ROUTE_USER_SUBSCRIPTION_NEW
    );
  }, [history, billing]);

  // handle state transition logic
  const handleSidebarState = (currentSidebarState: SidebarState) => {
    switch (currentSidebarState) {
      case SidebarState.HIDDEN:
        return SidebarState.MOBILE;
      case SidebarState.MOBILE:
        return SidebarState.HIDDEN;
      case SidebarState.EXPANDED:
        return SidebarState.COLLAPSED;
      case SidebarState.COLLAPSED:
        return SidebarState.EXPANDED;
      default:
        localStorage.removeItem("sidebarState");
        return null;
    }
  };

  const isInBankingOrOnboardingPath = BANKING_AND_ONBOARDING_PATHS.some(
    (path) => window.location.pathname.includes(path)
  );

  return (
    <Box
      id="topbar"
      w="100%"
      zIndex={2}
      display="flex"
      flexDir="column"
      {...props}
    >
      <UINavbar>
        {sidebarState === SidebarState.HIDDEN && (
          <UIButton
            className="px-3"
            onClick={() => {
              const newState = handleSidebarState(sidebarState);
              if (newState !== null) {
                setSidebarState(newState);
                localStorage.setItem("sidebarState", newState);
              }
            }}
          >
            <Icon className="h-4 w-4" name="Bars3" />
          </UIButton>
        )}
        <UINavbar.Search
          placeholder="Search"
          onSearch={onSearch}
          shortcut="f"
        />
        {sidebarState !== SidebarState.MOBILE && (
          <>
            <UINavbar.Spacer />
            <UINavbar.Item>
              {!isInBankingOrOnboardingPath &&
                (isPaperOrOnboarding(account) || props.product === "live") && (
                  <UIButton onClick={onDepositOrOpenAccountClick}>
                    {props.product === "paper" ? (
                      "Open Live Account"
                    ) : (
                      <>
                        <UIIcon name="PlusCircle" className="h-4 w-4 mr-1" />
                        Add Funds
                      </>
                    )}
                  </UIButton>
                )}
            </UINavbar.Item>
          </>
        )}
      </UINavbar>
    </Box>
  );
};

export default TopBar;
