import { createAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "../alpacaAuth/session";
import "fast-text-encoding";
import { getAccountIdForProduct } from "selectors";

const REDUCER = "trade";
const NS = `@@${REDUCER}/`;

const _setTradeDetails = createAction(`${NS}SET_TRADE_DETAILS`);

/**
 * loadTradeDetails loads the trading details for a specific account.
 * This includes the buying power, cash, cash withdrawable, portfolio value
 * and some other status flags.
 *
 * Note: Product/type may become an id in the future when multiple accounts are supported.
 * Account here needs all accounts and the account.details (for owner ID).
 *
 * @param {Object} account All of the user's accounts data (and owner details)
 * @param {String} product The type of account we're getting data for (currently paper or live, just one account for now)
 * @param {Boolean} getLast If true, get values for last trading day close
 */
export const loadTradeDetails = (
  account,
  product = "paper",
  getLast = false
) => (dispatch, getState) => {
  const ownerId =
    (getState().account &&
      getState().account.details &&
      getState().account.details.owner_id) ||
    false;

  if (account) {
    const tradeEndPoint =
      product === "paper" ? "paperTradeAccount" : "tradeAccount";
    // Temporary: For ETC the endpoint switches to the V2 to properly reflect 2x buying power
    const clearingBroker =
      (getState().account &&
        getState().account.account &&
        getState().account.account.clearing_broker) ||
      "APEX";

    const accountId = getAccountIdForProduct(getState(), product);

    if (accountId) {
      let method = "get";

      if (clearingBroker === "ETC" || clearingBroker === "VELOX") {
        method = getLast ? "getETCLast" : "getETC";
      }

      return handleSessionExpire(
        dispatch(api[tradeEndPoint][method]({ accountId })).then((trade) =>
          dispatch(
            _setTradeDetails({ accountId, type: product, ownerId, trade })
          )
        ),
        dispatch
      );
    }
    return;
  }
};

// Export this reducer
const initialState = {};
export default createReducer(
  {
    [_setTradeDetails]: (state, { accountId, trade }) => {
      // ie. trade.bfe87e76-5ad3-41d6-a12d-918142bb38df
      const newState = { ...state };
      newState[accountId] = { ...newState[accountId], ...trade };
      return newState;
    },
  },
  initialState
);
