import { createReducer } from "redux-act";
import Auth from "@aws-amplify/auth";
import { notification } from "antd";
import { getCurrentAuthenticatedUser, weakEncrypt } from "../../utils";
import * as Sentry from "@sentry/browser";

import * as api from "api";
import * as app from "../app";
import * as auth from "./common";
import * as cognitoActions from "reducers/auth/cognito";
import { submit as submitLogin } from "./login";
import { eraseCookie } from "lib/cookies";
import { eventTracker } from "src/utils/eventTracker";

export const REDUCER = "signup";

/**
 * Handles the form submit for user registration.
 * This will register a user with Cognito.
 *
 * @param {Object}
 */
export const submit = ({
  username,
  password,
  clearing_broker = "VELOX",
  client_id = "alpaca",
  utm_params = {},
}) => (dispatch) => {
  dispatch(app.addSubmitForm(REDUCER));

  // username IS an e-mail address in our case. form validation. intent.
  // NOTE: ensure this is lowercase as Cognito is case sensitive for now (they're changing that)
  const email = (username = username.toLowerCase());

  dispatch(
    api.owner.create(
      {},
      {
        email,
        password: weakEncrypt(password),
        crypto_broker:
          // has ?xcrypto in the url
          new URLSearchParams(window.location.search).has("xcrypto")
            ? "BS"
            : undefined,
        onboarding_version: "v1",
        clearing_broker,
        signup_source: client_id,
        utm_params,
      }
    )
  )
    .then(() => {
      // Track event to Segment that someone signed up (clicked the signup button)
      eventTracker("Signed Up", {
        category: "Account",
      });

      dispatch(
        auth.setUserState({
          userState: {
            userConfirmed: false, // user is certainly not confirmed yet right after signing up
          },
        })
      );

      // TODO: Again, think about moving this to signup.
      // The string 'signup' here is the signup reducer name. It's a constant in that file, it's magic here.
      dispatch(auth.setAccountVerificationRequired({ id: "signup", username }));
      dispatch(app.deleteSubmitForm("signup"));
    })
    .catch((err) => {
      dispatch(app.setFormInvalid({ id: "signup", message: err.message }));
      dispatch(app.deleteSubmitForm("signup"));
    });
};

export const verify = ({
  username,
  code,
  password,
  redirect = "",
  utm_params = {},
}) => (dispatch) => {
  dispatch(app.addSubmitForm(REDUCER));

  username = username.toLowerCase();

  // After retrieveing the confirmation code from the user
  return Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true,
  })
    .then(() => {
      // Track event to Segment that someone created and verified their account
      eventTracker("Verified Email", {
        category: "Account",
      });

      notification.open({
        type: "success",
        message: "Thank you!",
        description:
          "Your e-mail address has been verified, you may now login.",
      });

      Auth.signIn(username, password).then(() => {
        getCurrentAuthenticatedUser().then((user) => {
          const email = username;
          const clearing_broker =
            user.attributes && user.attributes["custom:clearing_broker"];
          const source =
            user.attributes && user.attributes["custom:signup_source"];
          dispatch(cognitoActions._receive(user));
          dispatch(auth.setUserState({ userState: user }));
          dispatch(
            api.account.create(
              {},
              { email, clearing_broker, source, utm_params }
            )
          )
            .then(() => {
              dispatch(submitLogin({ username, password, redirect }));
              dispatch(app.deleteSubmitForm(REDUCER));
            })
            .catch((err) => {
              Sentry.captureException({
                message: "Error creating account",
                username,
                error: err.toString(),
              });
            });
        });
      });
    })
    .catch((err) => {
      dispatch(auth.setAccountVerificationError(err));
      dispatch(app.deleteSubmitForm(REDUCER));

      Sentry.captureException({
        message: "Error after account verfication",
        username,
        error: err.toString(),
      });
    });
};

export const accountCreated = (response) => () => {
  eraseCookie("alpaca_ref_by");
  let referralSucceededOrUndefined = response["referral_succeeded"];
  if (referralSucceededOrUndefined !== undefined) {
    const referredBy = response["referred_by"];
    if (referralSucceededOrUndefined && referredBy) {
      eventTracker("Referral Succeeded", {
        category: "AccountCreation",
        referredBy: `${referredBy}`,
      });
    } else {
      const referralErrorMessage = response["referral_error"];
      eventTracker("Referral Failed", {
        category: "AccountCreation",
        error: referralErrorMessage,
      });
    }
  } else {
    eventTracker("Referral Unknown Status", {
      category: "AccountCreation",
    });
  }
  eventTracker("Account Creation Succeeded", {
    category: "AccountCreation",
  });
};

export const accountCreationFailed = (err) => () => {
  eventTracker(`Account Creation Failed: ${err.message}`, {
    category: "AccountCreation",
  });
};

const initialState = {};
export default createReducer({}, initialState);
