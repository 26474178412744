const setRequestedPath = ({ pathname, search }) => {
  window.sessionStorage.setItem("requested_path", pathname + search);
};

export const requireLogin = (cognitoUser, accountList, routeProps) => {
  if (!cognitoUser || !accountList) {
    setRequestedPath(routeProps.location);
    return "/login";
  }
  const emailVerified =
    cognitoUser.attributes && cognitoUser.attributes.email_verified;
  if (!emailVerified) {
    setRequestedPath(routeProps.location);
    return "/login";
  }
};

export const requireLiveActive = (cognitoUser, accountList, routeProps) => {
  let loginCheck = requireLogin(cognitoUser, accountList, routeProps);
  if (loginCheck) {
    return loginCheck;
  }

  const liveAccount = accountList[0];
  switch (liveAccount.status) {
    case "PAPER_ONLY":
    case "REJECTED":
      return "/paper/dashboard/overview";
    case "ONBOARDING":
      return "/brokerage/new-account";
  }
};

export const requireCrytpoActive = (cognitoUser, accountList, routeProps) => {
  let loginCheck = requireLogin(cognitoUser, accountList, routeProps);
  if (loginCheck) {
    return loginCheck;
  }

  const liveAccount = accountList[0];
  const enableCryptoTransfers = window.env.ENABLE_COIN_TRANSFERS ?? false;
  if (!enableCryptoTransfers) {
    return "/brokerage/dashboard/overview";
  }

  switch (liveAccount.crypto_status) {
    case "REJECTED":
      return "/paper/dashboard/overview";
    case "ONBOARDING":
      return "/brokerage/new-account";
  }
};

export const checkLogin = (cognitoUser, accountList) => {
  if (cognitoUser) {
    const emailVerified =
      cognitoUser.attributes && cognitoUser.attributes.email_verified;
    if (!emailVerified) {
      return "/login";
    }
    if (accountList) {
      return "/";
    }
  }
};
