import instance from "../";

import {
  CognitoUser,
  ISignUpResult,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import { Auth } from "aws-amplify";
import { Path } from "../../path";
import { clearCookieStorage } from "../../../reducers/auth/logout";
import { parseUtmParams } from "../../../utils";

export type CognitoUserExtended = CognitoUser & {
  username?: string;
  challengeName?: string;
  challengeParam?: {
    CODE_DELIVERY_DESTINATION: string;
    userAttributes: {
      email: string;
      [attrName: string]: string;
    };
    codeParameter: string;
  };
};

export const getAttributes = async (
  user: CognitoUser
): Promise<CognitoUserAttribute[]> =>
  new Promise((resolve, reject) => {
    user.getUserAttributes((err, data) => {
      if (err || !data) return reject(err);
      resolve(data);
    });
  });

export const getCustomAttribute = (
  attributes: CognitoUserAttribute[],
  attr: string,
  defaultValue?: string
): string => {
  for (const a of attributes) {
    if (a.Name === `custom:${attr}`) return a.Value;
    if (a.Name === attr) return a.Value;
  }

  if (defaultValue == undefined) {
    throw new Error(`cannot find custom attribute custom:${attr}`);
  }

  return defaultValue;
};

export const UserActions = {
  async getCurrentUser(): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser();
  },
  async confirmMultiFactorAuth(
    user: CognitoUser,
    code: string,
    mfaType: string
  ): Promise<CognitoUser> {
    return await Auth.confirmSignIn(user, code, mfaType as any);
  },
  async checkLoggedInStatus(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      return Boolean(user);
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  verifyRecoveryCode: async (
    cognitoId: string,
    verifyId: string,
    userPool: string
  ): Promise<void> => {
    const path = `/accounts/${cognitoId}/account-recovery/${verifyId}?userpool=${userPool}`;
    const response = await fetch(`${window.env.API_URL}${path}`);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Invalid recovery code provided");
    }
  },
  async registerUser(
    firstName: string,
    lastName: string,
    country: string,
    email: string,
    password: string,
    countryOfCitizenship: string,
    validationResponse: string,
    signupIPAddress: string
  ): Promise<ISignUpResult> {
    const params = new URLSearchParams(window.location.search);
    const utmParams = parseUtmParams(params);

    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        "custom:firstName": firstName,
        "custom:lastName": lastName,
        "custom:country": country,
        "custom:citizenship": countryOfCitizenship,
        "custom:onboardingVersion": "v2",
        "custom:accountCreated": "no",
        "custom:crypto_broker": params.has("xcrypto") ? "BS" : undefined,
        "custom:validationResponse": validationResponse,
        "custom:signupIPAddress": signupIPAddress,
        ...(Object.keys(utmParams).length && {
          "custom:utm_params": JSON.stringify(utmParams),
        }),
      },
    });
  },
  userLogin(email: string, password: string): Promise<CognitoUserExtended> {
    return Auth.signIn(email, password);
  },
  async userLogout(): Promise<void> {
    await Auth.signOut();
    clearCookieStorage();
    window.sessionStorage.clear();
    window.location.href = Path.ROUTE_LOGIN;
  },
  async confirmUserSignUp(email: string, code: string): Promise<void> {
    await Auth.confirmSignUp(email, code, {
      forceAliasCreation: true,
    });
  },
  async resendUserSignUp(username: string) {
    await Auth.resendSignUp(username);
  },
  async createUserAccountIfNotExist(user: CognitoUser) {
    const attributes = await getAttributes(user);
    const getAttr = (key: string, defaultValue?: string) =>
      getCustomAttribute(attributes, key, defaultValue);

    if (
      (await getAttr("onboardingVersion", "v1")) === "v2" &&
      (await getAttr("accountCreated")) !== "yes"
    ) {
      await instance.post("/accounts", {
        email: getAttr("email"),
        first_name: getAttr("firstName"),
        last_name: getAttr("lastName"),
        country: getAttr("country"),
        country_of_citizenship: getAttr("citizenship"),
        source: getAttr("signup_source", ""),
        crypto_broker: getAttr("crypto_broker", ""),
        utm_params: getAttr("utm_params", ""),
        clearing_broker: "VELOX",
        version: "v2",
      });

      // update attribute if account created
      await Auth.updateUserAttributes(user, {
        "custom:accountCreated": "yes",
      });
    }
  },
};

// @deprecated use UserActions instead
export const getCurrentUser = UserActions.getCurrentUser;
// @deprecated use UserActions instead
export const confirmMFA = UserActions.confirmMultiFactorAuth;

// @deprecated use UserActions instead
export const isLoggedIn = UserActions.checkLoggedInStatus;

// @deprecated use UserActions instead
export const register = UserActions.registerUser;

// @deprecated use UserActions instead
export const login = UserActions.userLogin;

// @deprecated use UserActions instead
export const signOut = UserActions.userLogout;

// @deprecated use UserActions instead
export const confirmSignUp = UserActions.confirmUserSignUp;

// @deprecated use UserActions instead
export const resendSignUp = UserActions.resendUserSignUp;

// @deprecated use UserActions instead
export const createAccountIfNotExist = UserActions.createUserAccountIfNotExist;

// @deprecated use UserActions instead
export const getCustomAttr = getCustomAttribute;

// @deprecated use UserActions instead
export const AuthService = {
  getCurrentUser,
  confirmMFA,
  isLoggedIn,
  register,
  login,
  signOut,
  confirmSignUp,
  resendSignUp,
  createAccountIfNotExist,
  getAttributes,
  getCustomAttr,
};
