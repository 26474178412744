import instance from "..";

export interface Transfer {
  id: string;
  status: string;
  amount: number;
  created_at: string;
  direction: string;
  type: string;
}
export interface AchTransfer extends Transfer {}

export const getAchTransfers = async (
  accountId: string
): Promise<Transfer[]> => {
  const res = await instance.get<Transfer[]>(
    `/accounts/${accountId}/transfers`
  );
  return res.data;
};

export interface CreateTransferRequest {
  amount: string;
  direction: string;
  relationship_id?: string;
  bank_id?: string;
  transfer_type: string;
}

export const createAchTransfer = async (
  accountId: string,
  req: CreateTransferRequest
): Promise<Transfer> => {
  const res = await instance.post<Transfer>(
    `/accounts/${accountId}/transfers`,
    req
  );
  return res.data;
};

export interface RapydTransfer extends Transfer {
  transfer_status: string;
}

export const getRapydTransfers = async (
  accountId: string
): Promise<Transfer[]> => {
  const res = await instance.get<RapydTransfer[]>(
    `/rapyd/${accountId}/transfers`
  );

  // standardize to fit the abstract transfer object
  res.data.forEach((t) => {
    t.status = t.transfer_status;
    t.type = "rapyd";
    t.direction = "INCOMING";
  });
  return res.data;
};

export interface AchRelationship {
  id: string;
  account_id: string;
  status: string;
  approval_method: string;
  account_name: string;
  plaid_institution: string;
}

export const getAchRelationships = async (
  accountId: string
): Promise<AchRelationship[]> => {
  const res = await instance.get<AchRelationship[]>(
    `/accounts/${accountId}/relationships`
  );
  return res.data;
};

export const deleteAchRelationship = async (
  accountId: string,
  relationshipId: string
): Promise<void> => {
  const res = await instance.delete<void>(
    `/accounts/${accountId}/relationships/${relationshipId}`
  );
  return res.data;
};

export interface BankInstitutionResponse {
  institution: BankInstitution;
}

export interface BankInstitution {
  logo: string;
  brand_name: string;
}

export const getBankInstitution = async (
  institutionId: string
): Promise<BankInstitution> => {
  const res = await instance.get<BankInstitutionResponse>(
    `/institutions/${institutionId}`
  );
  return res.data.institution;
};
