import get from "lodash/get";

/**
 * Selectors are helper functions that generalize common questions about state.
 *
 * It introduces an extra layer of indirection, but it pays for this
 * by providing a single source of truth for how to interpret the state.
 *
 * This makes it easier to reduce duplication and bad assumptions,
 * and to change the shape of state in the future.
 *
 * A note on functions that return boolean values:
 * If the function is written as `isAccountInSomeState`, it should only be used
 * to determine if that specific condition true. A false value should not be used to infer
 * some other state. For example, if `isMicrodeposit` is false for some relationship,
 * you should not assume that it is a Plaid relationship. There could be some third type
 * of relationship in the future, or the relationship could just be null.
 */

// account stuff

export const isAlpacaAccountCreated = (state) =>
  !!(state.account && state.account.account);

export const getAlpacaAccount = (state) => get(state, "account.account");

export const getAlpacaAccountId = (state) => get(state, "account.account.id");

export const getAlpacaOwnerId = (state) =>
  get(state, "account.details.owner_id");

/**
 * getTradeDetails is a selector for trade details ( state.trade.{accId} )
 * for example:
 * state.trade.ab0e59fd-0485-42e6-8d3c-cd5e2cbb5ae3.cash_withdrawable for
 * cash withdrawable of specified account
 *
 * @param {object} state state of the app.
 * @param {String} product 'live' or 'paper'
 * @returns {object} the trade details object.
 */
export const getTradeDetails = (state, product = "live") => {
  const accId = getAccountIdForProduct(state, product);
  return get(state, `trade.${accId}`);
};

export const getAccountDetails = (state) => get(state, "account.details");

export const getName = (state) => get(state, "account.account.name");

export const getEmail = (state) => get(state, "account.account.email");

export const getAccountStatus = (state) => get(state, "account.account.status");

export const getAccountNumber = (state) =>
  get(state, "account.account.account_number");

export const getPaperAccountId = (state) => get(state, "account.paper.current");

export const isPaperOnly = (state) =>
  get(state, "account.account.status") === "PAPER_ONLY";

export const getApiToken = (state) =>
  get(state, "auth.userState.signInUserSession.idToken.jwtToken");

export const isOAuthAccount = (account) =>
  ((account && account.details) || {}).signup_source !== null;

export const getOAuthClients = (state) => get(state, "oauth.clients") || [];

// A helper to return access token, account id and paper account id.
export const getAccountIdAndJWT = (state) => {
  const account = getAlpacaAccount(state) || {};
  const accountId = account.id || "";
  const paperAccountId = getPaperAccountId(state);
  const token = getApiToken(state);

  return { accountId, paperAccountId, token };
};

export const getLiveApiKey = (state) => {
  const keyId = Object.keys(state.accessKeys).find(
    (id) => state.accessKeys[id].product === "live"
  );
  return state.accessKeys[keyId];
};

// banking stuff

export const getRelationships = (state) => state.account.relationships;
export const getWireBanks = (state) => state.account.wireBanks;

export const getRelationshipById = (state, relationshipId) => {
  const relationships = getRelationships(state);
  if (!relationships) return null;
  return relationships.find((rel) => rel.id === relationshipId);
};

export const getPrimaryWireBank = (state) => get(state, "account.wireBanks[0]");

export const getPrimaryRelationship = (state) =>
  get(state, "account.relationships[0]");

export const isLegacyMicrodeposit = (relationship) =>
  relationship && relationship.plaid_institution === "micro_deposit";

export const isMicrodeposit = (relationship) =>
  relationship &&
  relationship.plaid_verification_status &&
  relationship.plaid_verification_status.includes("manual");

export const isPendingMicrodeposit = (relationship) =>
  isMicrodeposit(relationship) &&
  relationship.plaid_verification_status.includes("pending");

export const getInstitutionOfRelationship = (state, relationship) => {
  if (!relationship) return null;
  if (isMicrodeposit(relationship) || isLegacyMicrodeposit(relationship)) {
    return {
      name: relationship.account_name,
      logoUrl: "/resources/images/bank.svg",
    };
  }
  return state.account.institutions
    ? state.account.institutions[relationship.plaid_institution]
    : null;
};

/**
 * This will return the account ID for the given product.
 * Currently there is only one account per product, but this
 * will change in the future.
 *
 * @param {Object} state
 * @param {String} product
 */
export const getAccountIdForProduct = (state, product) => {
  if (product && state) {
    // Figure out the account id to use
    const { account } = state;
    let accountId = "";
    if (product === "paper") {
      accountId = (account.paper && account.paper.current) || accountId;
    }
    if (product === "live") {
      accountId = (account.account && account.account.id) || accountId;
    }

    return accountId;
  }
};

/**
 * Given a live account object, return if the account is in a state
 * of beinga be to be used.
 *
 * @param {Object} account
 */
export const isLiveAccountActive = (account) => {
  const accountStatus = (account && account.status) || false;

  // A full list of status values here:
  // https://github.com/alpacahq/gobroker/blob/develop/models/enum/enum.go#L17
  //
  // An 'ACTIVE' status will have an account_number so no need to check that.
  // SUBMITTED          - action is required on client side, some sort of rejection
  // RESUBMITTED        - request has been re-submitted to Apex after account update
  // APPROVAL_PENDING   - account awaiting approval
  // REAPPROVAL_PENDING - indeterminate state once apex approves a re-submission
  // ACTION_REQUIRED    - account did not pass automatic cip check or failed watchlist check, waiting for admin action
  // SUBMISSION_FAILED  - something happened on our server and didn't complete
  // REJECTED           - APEX flat out rejected the account (the user must re-apply somehow - new email/login)
  // :::  the following admin actions can change status :::
  // ACCOUNT_UPDATED    - if the account was previously in an ACTIVE status
  // EDITED             - temporary status when an admin has edited an account
  // ACCOUNT_CLOSED     - the account is closed (rare)
  // DISABLED           - ETC account is disabled by admin
  return accountStatus === "ACTIVE";
};

/**
 * Given an account object, this will return true if the
 * account is PAPER_ONLY or ONBOARDING
 *
 * @param {Object} account
 */
export const isPaperOrOnboarding = (account) => {
  const liveAccount = account.account || {};
  const { status = "" } = liveAccount;
  return (
    status === "ONBOARDING" || status === "PAPER_ONLY" || status === "SIGNED_UP"
  );
};

/**
 * Given an account object, this will return true if
 * the account is in onboarding.
 *
 * @param {Object} account
 */
export const isOnboarding = (account) => {
  const liveAccount = account.account || {};
  const { status = "" } = liveAccount;
  return (
    status === "PAPER_ONLY" || status === "ONBOARDING" || status === "SIGNED_UP"
  );
};

/**
 * Given an account object, this will return true if
 * the account is in `SUBMITTED` status, meaning they
 * submitted their application and completed onboarding
 * moving out of the `ONBOARDING` status.
 *
 * @param {Object} account
 */
export const isSubmitted = (account) => {
  const liveAccount = account.account || {};
  const { status = "" } = liveAccount;
  return status === "SUBMITTED";
};

// Check on name for disabled
export const isLiveAccountClosedOrDisabled = (account) => {
  const liveAccount = account.account || {};
  const { status = "" } = liveAccount;
  return status === "ACCOUNT_CLOSED" || status === "DISABLED";
};

// Returns true if either a live API key is generate or an app is configured
export const isTradingConnected = (accessKeys, oauthClients) => {
  let hasLiveAccessKey = false;
  Object.keys(accessKeys).forEach((ak) => {
    if (accessKeys[ak] && accessKeys[ak].product === "live") {
      hasLiveAccessKey = true;
    }
  });

  return (
    (accessKeys && hasLiveAccessKey) ||
    (oauthClients && oauthClients.length > 0)
  );
};
