import instance from "../";

import { CountryInfo } from "src/v2-deprecated/types";

export interface PreviousStepResponse {
  new_step: string;
}

export type CountryInfos = {
  [key: string]: CountryInfo;
};

export type DisclosureData = {
  is_control_person: boolean;
  is_politically_exposed: boolean;
  immediate_family_exposed: boolean;
  is_discretionary: boolean;
  non_solicitation_answer: string;
};

export interface AddressStepPayload {
  last_name?: string;
  first_name?: string;
  postal_code?: string;
  city?: string;
  state?: string;
  phone_number?: string;
  tax_id?: string;
  date_of_birth?: string;
  tax_id_type?: string;
  unit?: string;
  country?: string;
  street_address?: string[];
  permanent_resident?: boolean;
  signup_step?: string;
  country_of_citizenship?: string;
  country_of_tax_residence?: string;
  margin_agreement_signed?: boolean;
  customer_agreement_signed?: boolean;
  customer_agreement_revision?: string;
  visa_type?: string;
  visa_expiration_date?: string;
  country_of_birth?: string;
}

export interface ESignAudit {
  agreement: string;
  signed_at: string;
}

export interface AffiliateData {
  type: string;
  street_address?: string[];
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  company_name?: string;
  company_symbol?: string;
  additional_name?: string;
  compliance_email?: string;
  given_name?: string;
  family_name?: string;
}

export const acceptW8 = (accountID: string) =>
  instance.post(`/accounts/${accountID}/taxfiles/w_8ben/accept`, {});

export const createAffiliate = (accountID: string, payload: AffiliateData) =>
  instance.post(`/accounts/${accountID}/affiliates`, payload);

export const addressPatch = (accountID: string, payload: AddressStepPayload) =>
  instance.patch(`/accounts/${accountID}/steps/next/address_step`, payload);

export const disclosuresPatch = (
  accountID: string,
  payload: Partial<DisclosureData>
) =>
  instance.patch(`/accounts/${accountID}/steps/next/disclosures_step`, payload);

export const confirmDetails = (accountID: string) =>
  instance.patch(`/accounts/${accountID}/steps/next/confirmation_step`, {});

export const kycPatch = (accountID: string) =>
  instance.patch(`/accounts/${accountID}/steps/next/kyc_step`, {});

export const getRestrictedCountries = async (): Promise<string[]> =>
  (await instance.get("/restricted-countries")).data;

export const getCountryInfos = async (): Promise<CountryInfos> =>
  (await instance.get("/country-infos")).data;

export const previousStepPatch = (
  accountID: string
): Promise<PreviousStepResponse> =>
  instance.patch(`/accounts/${accountID}/steps/previous`, {});

export const resetPatch = (accountID: string, payload: AddressStepPayload) =>
  instance.patch(`/accounts/${accountID}/steps/reset`, payload);
