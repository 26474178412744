import instance from '../'

type ACHApprovalMethod =
  | 'MICRO_DEPOSIT'
  | 'PLAID'
  | 'PLAID_MICRO'
  | 'CORRESPONDENT'
  | 'PLAID_PARTNER'

type RelationshipStatus =
  | 'QUEUED'
  | 'CANCEL_REQUESTED'
  | 'CANCEL_SENT'
  | 'CANCEL_FAILED'
  | 'PENDING'
  | 'SENT_TO_CLEARING'
  | 'APPROVED'
  | 'CANCELED'
  | 'REJECTED'

export type PlaidVerificationStatus =
  | 'automatically_verified'
  | 'pending_automatic_verification'
  | 'pending_manual_verification'
  | 'manually_verified'
  | 'verification_expired'
  | 'verification_failed'

export type ACHRelationship = {
  id: string
  clearing_id?: string
  created_at: string
  updated_at: string
  deleted_at: string
  account_id: string
  status: RelationshipStatus
  approval_method: ACHApprovalMethod
  plaid_institution?: string
  plaid_verification_status?: PlaidVerificationStatus
  mask?: string
  account_name?: string
  expires_at?: string
  failed_attempts: number
  reason: string
  micro_deposit_id: string
  micro_deposit_status: string
  processor_token?: string
  correspondent_code?: string
}

type BankRelationship = {
  id: string
  account_id: string
  status: RelationshipStatus
  approval_method: ACHApprovalMethod
  account_name: string
  account_mask: string
  plaid_institution: string
  plaid_verification_status: string
  created_at: string
  expires_at: string
}

export type BankRelationships = BankRelationship[]

export type MutateBankRelationshipParams = {
  plaid: {
    public_token: string
    account_id: string
    name: string
    mask: string
    verification_status?: string
  }
}

export const getBankRelationships = async (accountID: string) => {
  const { data } = await instance.get<BankRelationships>(
    `/accounts/${accountID}/relationships`
  )
  return data
}
export const createBankRelationship = async (
  accountID: string,
  body: MutateBankRelationshipParams
) => {
  const { data } = await instance.post<ACHRelationship>(
    `/accounts/${accountID}/relationships`,
    body
  )

  return data
}

export type VerifyBankRelationshipArgs = {
  accountID: string
  relationshipID: string
  body: MutateBankRelationshipParams
}
export const verifyBankRelationship = async ({
  accountID,
  relationshipID,
  body,
}: VerifyBankRelationshipArgs) => {
  const { data } = await instance.post<ACHRelationship>(
    `/accounts/${accountID}/relationships/${relationshipID}/verify`,
    body
  )

  return data
}
