import { useDisclosure } from "@chakra-ui/react";
import { init, SdkOptions, StepTypes } from "onfido-sdk-ui";
import { useEffect, useRef } from "react";
import AmplitudeProvider from "src/AmplitudeProvider";
import { useMutationRequest } from "src/v2-deprecated/api/hooks";

import { getOnfidoSdkToken } from "../rest/onfido";

import { Account } from "../rest/account";
import {
  createOnfidoApplicant,
  patchOnfidoSdkToken,
  updateOnfidoApplicant,
  PatchOnfidoParams,
} from "../rest/onfido";
import useRequest, { QueryOptions } from "./useRequest";
import { eventTracker } from "src/utils/eventTracker";

// custom hook handles the logic for starting and closing the Onfido modal
type UseOnfidoOptions = {
  account?: Account;
  steps: StepTypes[];
  onComplete: () => void;
  onError: (err: string) => void;
};
export const useOnfido = ({
  account,
  steps,
  onComplete: completeCb,
  onError: errorCb,
}: UseOnfidoOptions) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    mutate: createApplicant,
    isSuccess: createdOnfido,
  } = useMutationRequest<Account, string>((accountID) =>
    createOnfidoApplicant(accountID)
  );

  const {
    mutate: updateApplicant,
    isSuccess: updatedOnfido,
  } = useMutationRequest<Account, string>((accountID) =>
    updateOnfidoApplicant(accountID)
  );

  const { data: sdkResponse, refetch: refetchToken } = useOnfidoToken(
    account?.id ?? "",
    {
      // dont fetch onfido token until one of the above mutations occur
      enabled: Boolean(account && (createdOnfido || updatedOnfido)),
    }
  );

  const {
    mutate: patchSDKToken,
  } = useMutationRequest((body: PatchOnfidoParams) =>
    patchOnfidoSdkToken(body)
  );

  const firstTime = useRef(true);
  const openModal = () => {
    // always use new onfido token whenever onfido sdk is open
    if (!firstTime.current) {
      refetchToken();
    }
    firstTime.current = false;
    onOpen();
  };

  useEffect(() => {
    if (!account) return;

    // Create Applicant if not exists
    const applicantExists = hasOnfidoApplicant(account);
    if (applicantExists) {
      // update onfido applicant
      updateApplicant(account.id);
    } else {
      // create onfido applicant
      createApplicant(account.id);
    }
  }, [Boolean(account)]);

  useEffect(() => {
    if (!sdkResponse || !account) return;
    const token = sdkResponse.onfido_sdk_token;
    // Start Onfido flow
    const opts: SdkOptions = {
      ...defaultOnfidoOptions,
      token,
      isModalOpen: isOpen,
      steps: steps,
      onComplete: () => {
        eventTracker("Finished Onfido SDK flow", {
          category: "Onboarding",
        });

        AmplitudeProvider.dispatch(
          "onfido_finished",
          JSON.stringify({ onfido_id: account?.onfido_applicant_id })
        );
        patchSDKToken({
          accountID: account.id,
          outcome: "USER_COMPLETED",
          token: token,
        });
        onClose();
        completeCb();
      },
      onError: (err) => {
        console.error("Onfido exception:", err);
        patchSDKToken({
          accountID: account.id,
          outcome: "SDK_ERROR",
          token: token,
        });
        errorCb(err.message);
      },
      onModalRequestClose: () => {
        eventTracker("Closed Onfido SDK flow", {
          category: "Onboarding",
        });

        onClose();
      },
    };

    eventTracker("Started Onfido SDK flow", {
      category: "Onboarding",
    });

    AmplitudeProvider.dispatch(
      "onfido_started",
      JSON.stringify({ onfido_id: account?.onfido_applicant_id })
    );

    const onfido = init(opts);

    return () => {
      onfido.tearDown();
    };
  }, [isOpen]);

  return { isReadyToStart: Boolean(sdkResponse), openModal };
};

const hasOnfidoApplicant = (account: Account): boolean =>
  Boolean(
    JSON.parse(window.localStorage.getItem("kyc") ?? "{}")?.[account.id] ||
      account?.onfido_applicant_id ||
      account?.onfido_details?.applicant_id
  );

const defaultOnfidoOptions = {
  useModal: true,
  containerId: "onfido-mount",
  customUI: {
    fontFamilyTitle: "Carnas, medium",
    fontFamilySubtitle: "Carnas, regular",
    fontFamilyBody: "Carnas, regular",
    fontSizeTitle: "26px",
    fontSizeSubtitle: "20px",
    fontSizeBody: "14px",
    fontWeightSubtitle: 600,
    colorContentButtonPrimaryText: "#000000",
    colorBackgroundButtonPrimary: "#FFD700",
  },
};

type OnfidoToken = { onfido_sdk_token: string };

const useOnfidoToken = (
  accountID: string,
  options: QueryOptions<OnfidoToken>
) =>
  useRequest<OnfidoToken>(
    ["onfidoSDK", accountID],
    () => getOnfidoSdkToken(accountID),
    options
  );

export default useOnfido;
