import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "reducers/auth/logout";
import { getBillingOverview } from "reducers/billing";
import { Dropdown, Menu } from "antd";
import { getAccount, resetOnboarding } from "reducers/account";
import { initUser } from "reducers/auth/common";
import {
  getAgreementPending,
  getHasFinishedOnboarding,
} from "src/utils/agreements";
import { getCountryInfos } from "src/reducers/countryInfo";
import { isCryptoEnabled } from "src/utils/crypto";
import { setFlag, useFlag } from "@alpacahq/flag-service";
import AmplitudeProvider from "../../../../AmplitudeProvider";
import { eventTracker } from "src/utils/eventTracker";

const mapDispatchToProps = (dispatch) => ({
  logout: (event) => {
    event.preventDefault();
    dispatch(logout());
  },
  getBillingOverview: () => dispatch(getBillingOverview()),
  getCountryInfo: () => dispatch(getCountryInfos()),
  getAccount: () => dispatch(getAccount()),
  showGuide: (event) => {
    event.preventDefault();
  },
  showGettingStarted: (event) => {
    event.preventDefault();
  },
  resetOnboarding: (event) => {
    event.preventDefault();
    dispatch(resetOnboarding());
  },
  initUser: () => dispatch(initUser(true)),
});

const mapStateToProps = (state) => ({
  billing: state.billing,
  userState: state.auth.userState,
  account: state.account,
  countryInfo: state.countryInfo.infoMap || {},
});

@connect(mapStateToProps, mapDispatchToProps)
class ProfileMenu extends React.Component {
  state = { count: 7, v2: useFlag("v2") };

  addCount = () =>
    this.setState({
      count: this.state.count + 1,
    });

  componentDidMount = () => {
    this.props.getBillingOverview();
    this.props.getAccount();
  };

  handleSupportLink = () =>
    eventTracker("Clicked Support Profile Menu Link", {
      category: "Support",
      label: window.location.pathname,
    });

  render() {
    const {
      userState,
      logout,
      resetOnboarding,
      billing,
      account,
      countryInfo,
    } = this.props;

    const { details } = account;

    const isProd = window.env.ENV === "PROD";
    const accountName = account?.account?.name;
    const accountStatus = account?.account?.status;
    const onboardingStep = account?.last_onboarding_step;
    const accountNum = account?.account?.account_number;
    const email = (userState.attributes && userState.attributes.email) || "";

    const notifications = JSON.parse(
      localStorage.getItem("notifications") || "{}"
    );

    const cryptoEnabled = isCryptoEnabled(
      details?.country_of_tax_residence,
      details?.state,
      countryInfo
    );

    const notified = (name) => notifications[name];
    const clear = (name) =>
      localStorage.setItem(
        "notifications",
        JSON.stringify({ ...notifications, [name]: true })
      );

    const menu = (
      <Menu
        selectable={false}
        style={{
          borderRadius: "8px",
          paddingTop: ".75rem",
          paddingBottom: ".75rem",
          maxWidth: "min-content",
          minWidth: "min-content",
        }}
      >
        <div style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ color: "#1e2022", fontWeight: 600 }}>
                {accountName}
              </div>
            </div>
            <span style={{ opacity: "0.5", fontWeight: 500 }}>
              {accountNum || "no-account-number"}
            </span>
          </div>
        </div>
        {getHasFinishedOnboarding(details) &&
          getAgreementPending(details, cryptoEnabled) &&
          // ensure not onbv2
          !account?.account?.last_onboarding_step &&
          account?.account?.last_onboarding_step !== "finished" && [
            <hr style={{ margin: ".5rem 1rem" }} />,
            <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              <div
                style={{
                  background: "rgba(237,76,120,0.1)",
                  color: "rgb(237,76,120)",
                  width: "100%",
                  fontSize: "13px",
                  padding: "0.5rem 0.75rem",
                  fontWeight: "400",
                  minWidth: "250px",
                }}
              >
                You have pending agreements, review and sign by&nbsp;
                <Link
                  to="/user/profile"
                  style={{
                    textDecoration: "underline",
                    fontWeight: 500,

                    color: "rgb(237,76,120)",
                  }}
                >
                  clicking here
                </Link>
                .
              </div>
            </div>,
          ]}
        <hr style={{ margin: ".5rem 1rem" }} />
        {billing?.overview?.lifetimeSubCount > 0 ? (
          <Menu.Item>
            <Link
              to="/user/subscription"
              amplitude-event-name="market_data_subscription_clicked"
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                class="ri-flashlight-fill"
                style={{ marginRight: ".5rem" }}
              ></i>
              Market Data Subscription
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <Link
              to="/brokerage/new-account/data-subscription?upd=1"
              amplitude-event-name="market_data_subscription_clicked"
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="ri-flashlight-fill"
                style={{ marginRight: ".5rem" }}
              />
              Market Data Subscription
            </Link>
          </Menu.Item>
        )}
        <Menu.Item>
          <Link
            to="/user/profile"
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i class="ri-user-3-fill" style={{ marginRight: ".5rem" }}></i>
            Profile
          </Link>
        </Menu.Item>
        <Menu.Item>
          <a
            href="https://alpaca.markets/support"
            target="_blank"
            onClick={this.handleSupportLink}
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i
              class="ri-customer-service-fill"
              style={{ marginRight: ".5rem" }}
            ></i>
            Support
          </a>
        </Menu.Item>
        <hr style={{ margin: ".5rem 1rem" }} />
        <Menu.Item>
          <Link
            onClick={() => {
              setFlag("uix-v2", !this.state.v2);
              AmplitudeProvider.dispatch(
                "dropdown_switch_to_new_button_clicked"
              );
              window.location.reload();
            }}
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="ri-toggle-line" style={{ marginRight: ".5rem" }} />
            Switch to new UI
          </Link>
        </Menu.Item>
        <hr style={{ margin: ".5rem 1rem" }} />
        <Menu.Item>
          <Link
            onClick={logout}
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i
              className="ri-logout-box-line"
              style={{ marginRight: ".5rem" }}
            />
            Logout
          </Link>
        </Menu.Item>
        {!isProd && <hr style={{ margin: ".5rem 1rem" }} />}
        {!isProd && (
          <Menu.Item>
            <Link
              onClick={resetOnboarding}
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i class="ri-restart-fill" style={{ marginRight: ".5rem" }}></i>{" "}
              Reset Onboarding
            </Link>
          </Menu.Item>
        )}
      </Menu>
    );

    return userState.username ? (
      <div className="topbar__dropdown d-flex">
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          amplitude-event-name="account_dropdown_clicked"
          placement="bottomRight"
          onVisibleChange={this.addCount}
          onClick={() => clear("agreements")}
        >
          <a className="ant-dropdown-link" href="/">
            <strong
              style={{ fontSize: "14px", color: "#000", fontWeight: 500 }}
            >
              {getHasFinishedOnboarding(account.details) &&
                getAgreementPending(account.details, cryptoEnabled) &&
                !notified("agreements") && (
                  <div
                    style={{
                      top: "23px",
                      left: "-3px",
                      position: "absolute",
                      height: "6px",
                      width: "6px",
                      borderRadius: "50%",
                      background: "#fb434a",
                    }}
                  />
                )}
              {email}
            </strong>
          </a>
        </Dropdown>
      </div>
    ) : null;
  }
}

export default ProfileMenu;
