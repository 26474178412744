/**
 * The left <Sider> sidebar main nav menu data.
 *
 * EXTERNAL LINKS
 * To make an external link work, have `url` be some external http/https link.
 * Then ensure the `key` value starts with "http" (ie. "httpMyExternalLink").
 * Optionally set `target` (will be "_blank" by default).
 *
 * DYNAMIC MENU ITEM TITLES
 * Certain menu item titles need to change, for example if a user has not yet
 * created a brokerage account, it should say "Start" instead of "Go to..."
 * So a function can be used for the `title` key's value where it will be given
 * the props (which includes info like the product, accounts, etc.) to help make
 * the determination.
 *
 * PRODUCTS
 * The dashboard is now taking on a product "context" that is to indicate which Alpaca
 * product/service (and account within that product) the user is working with. While we
 * could load different menuData.js files (TODO: maybe a good refactor), we can choose when
 * to show menu items by setting a `product` key value. It's just an array of names for now.
 *
 * HIDE IF...
 * Menu items can be completely hidden under certain circumstances as well. If a `hideIf`
 * key is provided for a menu item, it's function value will be given props. It then can
 * make the determination to hide the item if it returns true.
 *
 * Remember: If async data from props is needed, it may be good to test if that data is
 * available yet. It will prevent "flicker" where a menu item appears and then hides
 * on a re-render after data loads from the API or something.
 *
 * MODALS
 * To use a modal for a link, ensure `url` is set to "modal"
 * Currently, these are handled manually in MenuLeft/index.js
 * So a new modal will need to be created there and shown.
 * TODO: Allow another key here like `modalContent` to return a component or at least link to one.
 * Keeping this file cleaner, maybe loadable() ?
 *
 */
import { isPaperOrOnboarding, isLiveAccountClosedOrDisabled } from "selectors";
import { inSupportedLocation } from "src/utils/crypto";

const enableCryptoTransfers =
  JSON.parse(window.env.ENABLE_COIN_TRANSFERS) === true;

export const isNotFullActiveAccount = (account) =>
  !account.account ||
  isPaperOrOnboarding(account) ||
  isLiveAccountClosedOrDisabled(account);

const menuData = [
  {
    title: "Overview",
    key: "brokerageOverviewDashboard",
    url: "/brokerage/dashboard/overview",
    icon: "fa fa-home",
    additionalClassNames: "brokerage-item",
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return isNotFullActiveAccount(account);
    },
  },
  {
    title: "Paper Overview",
    key: "paperOverviewDashboard",
    url: "/paper/dashboard/overview",
    icon: "fa fa-home",
    product: ["paper"],
  },
  {
    title: "Positions",
    key: "brokeragePortfolio",
    url: "/brokerage/dashboard/portfolio",
    icon: "fa fa-briefcase",
    additionalClassNames: "brokerage-item",
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return isNotFullActiveAccount(account);
    },
  },
  {
    title: "Orders",
    key: "brokerageOrders",
    url: "/brokerage/dashboard/orders",
    icon: "fa fa-file",
    additionalClassNames: "brokerage-item",
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return isNotFullActiveAccount(account);
    },
  },
  {
    title: "Account",
    key: "liveAccount",
    product: ["live"],
    icon: "fa fa-id-card",
    additionalClassNames: "brokerage-item",
    items: [
      {
        title: "Activities",
        key: "brokerageAccountActivities",
        url: "/brokerage/dashboard/account-activities",
        icon: "fa fa-file-alt",
        additionalClassNames: "brokerage-item",
        product: ["live"],
      },
      {
        title: "Balances",
        key: "brokerageBalances",
        url: "/brokerage/dashboard/balances",
        additionalClassNames: "brokerage-item",
        icon: "fa fa-wallet",
        product: ["live"],
      },
      {
        title: "Profile",
        key: "brokerageProfile",
        url: "/brokerage/dashboard/profile",
        additionalClassNames: "brokerage-item",
        icon: "fa fa-user",
        product: ["live"],
      },
      {
        title: "Documents",
        key: "brokerageDoc",
        url: "/brokerage/dashboard/documents",
        icon: "fa fa-file-alt",
        additionalClassNames: "brokerage-item",
        product: ["live"],
      },
      {
        title: "Configuration",
        key: "accountConfiguration",
        url: "/brokerage/dashboard/config",
        additionalClassNames: "brokerage-item",
        icon: "fa fa-cog",
        product: ["live"],
      },
    ],
    hideIf: (props) => {
      const { account = {} } = props;
      return isNotFullActiveAccount(account);
    },
  },
  {
    title: "Paper Positions",
    key: "paperPortfolio",
    url: "/paper/dashboard/portfolio",
    icon: "fa fa-briefcase",
    product: ["paper"],
  },
  {
    title: "Paper Orders",
    key: "paperOrders",
    url: "/paper/dashboard/orders",
    icon: "fa fa-file",
    product: ["paper"],
  },
  {
    title: "Paper Account",
    key: "paperAccount",
    icon: "fa fa-id-card",
    product: ["paper"],
    items: [
      {
        title: "Activities",
        key: "paperAccountActivities",
        url: "/paper/dashboard/account-activities",
        icon: "fa fa-file-alt",
        product: ["paper"],
      },
      {
        title: "Balances",
        key: "paperBalances",
        url: "/paper/dashboard/balances",
        icon: "fa fa-wallet",
        product: ["paper"],
      },
      {
        title: "Configuration",
        key: "accountConfiguration",
        url: "/paper/dashboard/config",
        icon: "fa fa-cog",
        product: ["paper"],
      },
    ],
  },

  {
    title: "Banking",
    key: "brokerageBanking",
    url: "/brokerage/banking",
    icon: "fa fa-landmark",
    additionalClassNames: "brokerage-item",
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return isNotFullActiveAccount(account);
    },
  },
  // allows users with closed accounts to still access old documents
  {
    title: "Documents",
    key: "brokerageDoc",
    url: "/brokerage/dashboard/documents",
    icon: "fa fa-file-alt",
    additionalClassNames: "brokerage-item",
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return !account.account || !isLiveAccountClosedOrDisabled(account);
    },
  },
  {
    divider: true,
    product: ["paper"],
  },
  {
    divider: true,
    product: ["live"],
    hideIf: (props) => {
      const { account = {} } = props;
      return (
        !account.account ||
        isPaperOrOnboarding(account) ||
        (props.clearingBroker &&
          props.clearingBroker !== "ETC" &&
          props.clearingBroker !== "VELOX")
      );
    },
  },
  {
    title: "Alpaca Connect",
    key: "brokerageApps",
    url: "/apps",
    icon: "fa fa-th",
    product: ["live", "paper"],
  },
  {
    divider: true,
    product: ["paper", "live"],
  },
  {
    title: "Documentation",
    key: "httpResourcesDashboard",
    url: "https://docs.alpaca.markets",
    target: "_blank",
    icon: "fa fa-book",
  },
  {
    title: "Status Page",
    key: "httpResourcesStatusPage",
    url: "https://status.alpaca.markets",
    target: "_blank",
    icon: "fa fa-notes-medical",
  },
  {
    title: "Community Slack",
    key: "httpSlackCommunity",
    url: "https://alpaca.markets/slack",
    target: "_blank",
    icon: "fab fa-slack-hash",
    additionalClassNames: "slack-community-link",
  },
  {
    title: "Community Forum",
    key: "httpForumCommunity",
    url: "https://forum.alpaca.markets/",
    target: "_blank",
    icon: "fas fa-users",
    additionalClassNames: "forum-community-link",
  },
];

if (enableCryptoTransfers) {
  menuData.splice(9, 0, {
    title: "Crypto Transfers",
    key: "brokerageCryptoTransfers",
    url: "/brokerage/dashboard/crypto-transfers",
    icon: "fas fa-exchange-alt",
    additionalClassNames: "brokerage-item",
    isNew: true,
    product: ["live"],
    hideIf: (props) => {
      const { accountDetails, account = {} } = props;

      if (!inSupportedLocation(accountDetails)) {
        return true;
      }

      return isNotFullActiveAccount(account);
    },
  });
}
export default menuData;
