import { createAction, createReducer } from "redux-act";
import { getAccountIdForProduct } from "selectors/index";
import { handleSessionExpire } from "./alpacaAuth/session";
import api from "api";

const REDUCER = "contest";
const NS = `@@${REDUCER}/`;

const _setContests = createAction(`${NS}SET_CONTESTS`);
const _setContestEnrollment = createAction(`${NS}SET_CONTEST_ENROLLMENT`);
const _setCurrentLeaderboard = createAction(`${NS}SET_CURRENT_LEADERBOARD`);
const _setAllTimeLeaderboard = createAction(`${NS}SET_ALLTIME_LEADERBOARD`);
const _setLeaderboardProfile = createAction(`${NS}SET_LEADERBOARD_PROFILE`);

/**
 * getContests will retrieve a list of all contests
 */
export const getContests = () => (dispatch) =>
  dispatch(api.contests.list())
    .then((payload) => dispatch(_setContests({ contestList: payload })))
    .catch(() => dispatch(_setContests({ contestList: [] })));

/**
 * enrollContest will enroll the user in the given contest
 */
export const enrollContest = (
  contestId,
  email,
  username,
  picture_url,
  strategy
) => (dispatch, getState) => {
  const paperAccountId = getAccountIdForProduct(getState(), "paper");
  const params = { email, username, picture_url, strategy };

  return handleSessionExpire(
    api.contests.enrollment
      .post({ paperAccountId, contestId }, params)(dispatch, getState)
      .then(() => dispatch(getContestEnrollment(contestId))),
    dispatch
  );
};

/**
 * getContestEnrollment will check if the user is enrolled in the given contest
 */
export const getContestEnrollment = (contestId) => (dispatch, getState) => {
  const paperAccountId = getAccountIdForProduct(getState(), "paper");

  return handleSessionExpire(
    api.contests.enrollment
      .get({ paperAccountId, contestId })(dispatch, getState)
      .then((payload) =>
        dispatch(_setContestEnrollment({ contestEnrollment: payload }))
      )
      .catch(() =>
        dispatch(_setContestEnrollment({ contestEnrollment: null }))
      ),
    dispatch
  );
};

/**
 * deleteContestEnrollment will delete user contest enrollment in the given
 * contest.
 */
export const deleteContestEnrollment = (contestId) => (dispatch, getState) => {
  const paperAccountId = getAccountIdForProduct(getState(), "paper");

  return handleSessionExpire(
    api.contests.enrollment
      .delete({ paperAccountId, contestId })(dispatch, getState)
      .then(() => dispatch(_setContestEnrollment({ contestEnrollment: null }))),
    dispatch
  );
};

/**
 * patchContestEnrollment will update user contest enrollment to be linked to the
 * given account.
 */
export const patchContestEnrollment = (contestId) => (dispatch, getState) => {
  const paperAccountId = getAccountIdForProduct(getState(), "paper");
  console.log("New Account: ", paperAccountId);

  return handleSessionExpire(
    api.contests.enrollment
      .patch({ paperAccountId, contestId })(dispatch, getState)
      .then((payload) =>
        dispatch(_setContestEnrollment({ contestEnrollment: payload }))
      )
      .catch(() =>
        dispatch(_setContestEnrollment({ contestEnrollment: null }))
      ),
    dispatch
  );
};

/**
 * getLeaderboard will retrieve the leaderboard for a given contest
 */
export const getLeaderboard = (contestId) => (dispatch) =>
  dispatch(api.contests.leaderboard.get({ contestId })).then((payload) =>
    dispatch(_setCurrentLeaderboard({ currentLeaderboard: payload }))
  );

/**
 * getAllTimeLeaderboard will retrieve the leaderboard for a given contest
 */
export const getAllTimeLeaderboard = () => (dispatch) =>
  dispatch(api.contests.leaderboard.getAllTime()).then((payload) =>
    dispatch(_setAllTimeLeaderboard({ allTimeLeaderboard: payload }))
  );

/**
 * getLeaderboardProfile will retrieve the leaderboard for a given contest
 */
export const getLeaderboardProfile = (contestId, participantId) => (dispatch) =>
  dispatch(
    api.contests.leaderboard.getParticipant({ contestId, participantId })
  ).then((payload) =>
    dispatch(_setLeaderboardProfile({ leaderboardProfile: payload }))
  );

// Export this reducer
const initialState = {};
export default createReducer(
  {
    [_setContests]: (state, { contestList }) => {
      return { ...state, contestList };
    },
    [_setContestEnrollment]: (state, { contestEnrollment }) => {
      return { ...state, contestEnrollment };
    },
    [_setCurrentLeaderboard]: (state, { currentLeaderboard }) => {
      return { ...state, currentLeaderboard };
    },
    [_setAllTimeLeaderboard]: (state, { allTimeLeaderboard }) => {
      return { ...state, allTimeLeaderboard };
    },
    [_setLeaderboardProfile]: (state, { leaderboardProfile }) => {
      return { ...state, leaderboardProfile };
    },
  },
  initialState
);
