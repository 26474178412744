import useRequest, { QueryOptions } from "./useRequest";

import { getMargin, Margin } from "../rest/account";

export const useGetMargin = (
  key: any | any[] = "",
  accountID: string = "",
  paper: boolean,
  options?: QueryOptions<Margin>
) => {
  const { data: margin } = useRequest(key, () => getMargin(accountID, paper), {
    ...options,
  });

  return { margin };
};

export default useGetMargin;
