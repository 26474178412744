import Spinner from "../../../../../components/common/spinner";

import React, { useEffect, useState, useContext } from "react";

import AmplitudeProvider from "src/AmplitudeProvider";

import {
  getCashTransformer,
  getPrettyCash,
} from "../../../../../utils/formatting";

import {
  createAchTransfer,
  deleteAchRelationship,
} from "src/v2-deprecated/api/rest/banking";

import {
  Text,
  Box,
  Image,
  VStack,
  Button,
  useToast,
  Flex,
  InputGroup,
  Input,
  Icon,
  InputRightElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

import {
  BankingContext,
  PendingRelationshipType,
  TransferDirection,
} from "../../";

import {
  usePlaid,
  useGetBankInstitution,
  useMutationRequest,
} from "src/v2-deprecated/api/hooks";

import { RiCloseCircleLine } from "react-icons/ri";
import { useForm, Controller } from "react-hook-form";
import { getCashWithdrawable } from "../../../../../../pages/Dashboard/Product/BrokerageAccount/Banking/BankingContainer/CurrencyCloud/constants";

interface Fields {
  amount: string;
}

// todo: make a bit more dynamic & modular; cleanup
export const Ach = () => {
  const toast = useToast();
  const ctx = useContext(BankingContext);

  const [submitCheck, setSubmitCheck] = useState(false);
  const [withdrawable, setWithdrawable] = useState(0);
  const [btnEnabled, setBtnEnabled] = useState(true);

  const { open: onOpenPlaid, ready } = usePlaid(ctx.account, {
    onExit: () => {
      ctx.onBeforeClose();
      ctx.setTransferType(null);
    },
    onSuccess: () => {
      ctx.setRelationshipPending(true, PendingRelationshipType.ADDED);

      toast({
        title: "Creating bank relationship...please wait",
        status: "success",
        duration: 5000,
        containerStyle: { zIndex: 999999 },
      });
    },
  });

  const isDeposit = ctx.transferDirection === TransferDirection.INCOMING;
  const relationshipExists =
    ctx.relationships &&
    ctx.relationships.length > 0 &&
    ctx.relationships[0].status !== "REJECTED";

  const { institution } = useGetBankInstitution(
    (relationshipExists && ctx.relationships[0].plaid_institution) || "",
    "institution",
    { enabled: Boolean(relationshipExists) }
  );

  const { control, watch, setValue } = useForm<Fields>();

  // real-time variables
  const amountString = watch("amount");
  const amount = amountString
    ? Number(amountString.replace(/[^0-9.]/g, ""))
    : 0;
  const withdrawableString = getPrettyCash(withdrawable, { acceptZero: true });
  const isWithdrawalInvalid = !isDeposit && amount > 0 && amount > withdrawable;
  const isConfirmDisabled = isDeposit
    ? amount <= 0
    : amount <= 0 || withdrawable < amount;

  // used in the rendering of the "to" and "from" cards
  const infos = [
    {
      imgSrc: "data:image/png;base64,".concat(institution?.logo || ""),
      label: relationshipExists && ctx.relationships[0].account_name,
    },
    {
      imgSrc: "/resources/images/logo-collapsed.svg",
      label: `Alpaca   Account ${ctx.account?.account_number}`,
    },
  ];

  const { mutate: createTransfer } = useMutationRequest(
    () => {
      setBtnEnabled(false);
      return createAchTransfer(ctx.account?.id || "", {
        transfer_type: "ach",
        direction: isDeposit ? "INCOMING" : "OUTGOING",
        relationship_id: ctx.relationships?.[0].id || "",
        amount: String(amount),
      });
    },
    {
      onSuccess: () => {
        if (isDeposit) {
          // track in Amplitude
          AmplitudeProvider.dispatch(
            "deposit_submitted_v2",
            JSON.stringify({
              amount: amount ?? "-",
            })
          );
        }
        ctx.refetch();
        ctx.onBeforeClose();
        setBtnEnabled(true);

        toast({
          title: "Transfer created",
          status: "success",
          duration: 5000,
          containerStyle: { zIndex: 999999 },
        });
      },
    }
  );

  const { mutate: deleteRelationship } = useMutationRequest(
    () =>
      deleteAchRelationship(
        ctx.account?.id || "",
        ctx.relationships?.[0].id || ""
      ),
    {
      onSuccess: () => {
        ctx.setTransferType(null);
        ctx.setNextStep(false);
        ctx.setRelationshipPending(true, PendingRelationshipType.DELETED);

        toast({
          title: "Bank relationship deleted",
          status: "success",
          duration: 5000,
          containerStyle: { zIndex: 999999 },
        });
      },
    }
  );

  // fetch and set withdrawable amount
  useEffect(() => {
    getCashWithdrawable(ctx.account?.id, (value: string) =>
      setWithdrawable(Number(value))
    );
  }, [ctx.account]);

  // pop plaid modal if no relationship exists
  useEffect(() => {
    if (ready && !relationshipExists) {
      // close the transfer modal to avoid conflict w plaid modal
      ctx.onBeforeClose();

      // open plaid modal
      onOpenPlaid();
    }
  }, [ready, relationshipExists]);

  const RemoveRelIcon = () => (
    <Flex alignItems="center">
      <Icon
        cursor="pointer"
        fontSize="xl"
        color="gray.50"
        as={RiCloseCircleLine}
        onClick={() => deleteRelationship({})}
      />
    </Flex>
  );

  if (!ready || (ready && !relationshipExists)) {
    return <Spinner />;
  }

  return (
    <>
      <Text fontSize="xl" fontWeight="600" mb="2.8rem">
        Enter an amount
      </Text>
      {ctx.account?.admin_configurations?.allow_instant_ach && (
        <Box bg="green.70" borderRadius="md" p=".7rem 1.4rem" mb="1.4rem">
          <Text color="green.10" fontWeight="500">
            Your account qualifies for Instant Funding up to $1,000, one settled
            transaction at a time.
          </Text>
        </Box>
      )}
      <FormControl isInvalid={isWithdrawalInvalid}>
        <InputGroup>
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                p="sm"
                fontSize="xl"
                fontWeight="500"
                variant="skeleton"
                {...getCashTransformer({ field })}
              />
            )}
          />
          {!isDeposit && (
            <InputRightElement
              pr="1rem"
              width="unset"
              children={
                <Text whiteSpace="nowrap" fontWeight="500" color="gray.50">
                  {withdrawableString}
                  &nbsp;available to withdraw
                </Text>
              }
            />
          )}
        </InputGroup>
        <FormErrorMessage px="1rem">
          {isWithdrawalInvalid &&
            `Cannot withdraw more than ${withdrawableString}. Some deposits take up to
          7 days to settle, after which they will be made available for
          withdrawal`}
        </FormErrorMessage>
      </FormControl>
      <Flex mb="1.4rem" mt="1rem" fontWeight="500">
        {["$500", "$1,000", "$10,000"].map((amount, index) => (
          <Box
            border="1px solid var(--chakra-colors-gray-20)"
            p=".4rem .6rem"
            borderRadius=".4rem"
            mr={index === 2 ? "0" : "1rem"}
            cursor="pointer"
            onClick={() => {
              setValue("amount", amount);
            }}
          >
            {amount}
          </Box>
        ))}
      </Flex>
      <Box>
        <Text fontWeight="500" fontSize="md" mb="1.4rem">
          From:
        </Text>
        <Flex
          p=".4rem 1rem"
          rounded="md"
          alignItems="center"
          justifyContent="start"
          border="1px solid var(--chakra-colors-gray-20)"
        >
          <Image
            boxSize="24px"
            my=".5rem"
            src={infos[isDeposit ? 0 : 1].imgSrc}
          />
          <Text fontWeight="500" fontSize="md" ml="1rem">
            {infos[isDeposit ? 0 : 1].label}
          </Text>
          <Box ml="auto">{isDeposit && <RemoveRelIcon />}</Box>
        </Flex>
      </Box>
      <Box mt="1.4rem">
        <Text fontWeight="500" fontSize="md" mb="1.4rem">
          To:
        </Text>
        <Flex
          p=".4rem 1rem"
          rounded="md"
          alignItems="center"
          justifyContent="start"
          border="1px solid var(--chakra-colors-gray-20)"
        >
          <Image
            boxSize="24px"
            my=".5rem"
            src={infos[isDeposit ? 1 : 0].imgSrc}
          />
          <Text fontWeight="500" fontSize="md" ml="1rem">
            {infos[isDeposit ? 1 : 0].label}
          </Text>
          <Box ml="auto">{!isDeposit && <RemoveRelIcon />}</Box>
        </Flex>
      </Box>
      <Box my="1.4rem">
        {submitCheck ? (
          <VStack>
            <Text mb="0.5rem" fontWeight="500">{`Initiate ${
              isDeposit ? "deposit" : "withdrawal"
            } of ${amountString}?`}</Text>
            <Box width="100%" justifyContent="space-between" display="flex">
              <Button
                disabled={!btnEnabled}
                onClick={() =>
                  createTransfer(
                    {},
                    {
                      onSuccess: () => {
                        ctx.setTransferType(null);
                        ctx.onBeforeClose();
                      },
                      onError: () => {
                        setBtnEnabled(true);
                      },
                    }
                  )
                }
                flexGrow={1}
                mr="1rem"
              >
                Yes
              </Button>
              <Button
                variant="skeleton"
                flexGrow={1}
                onClick={() => setSubmitCheck(false)}
              >
                Cancel
              </Button>
            </Box>
          </VStack>
        ) : (
          <Button
            w="100%"
            onClick={() => setSubmitCheck(true)}
            disabled={isConfirmDisabled}
          >
            Confirm
          </Button>
        )}
      </Box>
      <p>
        Note: You will not be able to withdraw newly deposited money for 6
        business days. Alpaca partners with BMO Harris Bank to process ACH
        transfers.
      </p>
    </>
  );
};

export default Ach;
