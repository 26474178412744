import useRequest, { QueryOptions } from "./useRequest";

import { Transfer, getAchTransfers } from "../rest/banking";
import { QueryObserverResult } from "react-query";

type UseGetAchTransfers = {
  achTransfers?: Transfer[];
  refetch: () => Promise<QueryObserverResult<Transfer[], unknown>>;
};

export const useGetAchTransfers = (
  accountId: string,
  key: any | any[] = "",
  options?: QueryOptions<Transfer[]>
): UseGetAchTransfers => {
  const { data: achTransfers, isError, isSuccess, refetch } = useRequest(
    "transfers" + key,
    () => getAchTransfers(accountId),
    {
      ...options,
      refetchInterval: false,
    }
  );

  return { achTransfers, refetch };
};
