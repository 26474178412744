import React from "react";
import PropTypes from "prop-types";
import App from "src/v2-deprecated/routes";
import Page from "src/v2-deprecated/components/common/page";
import TopBar from "components/LayoutComponents/TopBar";
import Menu from "components/LayoutComponents/Menu";
import LayoutState from "components/LayoutComponents/LayoutState";
import classNames from "classnames";
import loadable from "@loadable/component";
import LoadingSpinner from "components/LoadingSpinner";

import { useFlag } from "@alpacahq/flag-service";
import { enquireScreen } from "enquire-js";
import { cond, stubTrue } from "lodash/fp";
import { ContainerQuery } from "react-container-query";
import { Layout as AntLayout } from "antd";

const AntContent = AntLayout.Content;
const AntHeader = AntLayout.Header;

const query = {
  "screen-xs": {
    maxWidth: 575,
  },
  "screen-sm": {
    minWidth: 576,
    maxWidth: 767,
  },
  "screen-md": {
    minWidth: 768,
    maxWidth: 991,
  },
  "screen-lg": {
    minWidth: 992,
    maxWidth: 1199,
  },
  "screen-xl": {
    minWidth: 1200,
    maxWidth: 1599,
  },
  "screen-xxl": {
    minWidth: 1600,
  },
};

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class Layout extends React.Component {
  static defaultProps = {
    disableAppMenu: false,
    headerContent: null,
    noHeader: false,
    showBrandInHeader: false,
    showSearchInHeader: true,
    product: "alpaca",
  };

  static propTypes = {
    disableAppMenu: PropTypes.bool,
    headerContent: PropTypes.any,
    noHeader: PropTypes.bool,
    showBrandInHeader: PropTypes.bool,
    showSearchInHeader: PropTypes.bool,
    product: PropTypes.string,
  };

  child = null;
  importer = null;

  state = {
    isMobile,
  };

  componentDidMount() {
    this.enquireHandler = enquireScreen((mobile) => {
      this.setState({
        isMobile: mobile,
      });
    });
  }

  render() {
    if (useFlag("uix-v2")) {
      const { product } = this.props;

      // only save non-dynamic product state to local storage
      if (product !== "dynamic") {
        window.localStorage.setItem("product", product);
      }

      // set product from local storage to mimic a persistent state
      if ((this.props = { ...this.props }).product === "dynamic") {
        this.props.product = window.localStorage.getItem("product") || "paper";
      }

      const isMobile = !!this.state.isMobile;
      const {
        disableAppMenu,
        headerContent,
        noHeader,
        importer,
        ...more
      } = this.props;

      // Accept a loadable component using the `importer` prop.
      // Normal composition follows with `this.props.children`. See for example NotFoundPage.
      // We are imitating useMemo to preserve loadable between updates if we are
      // given the same importer
      const LoadableChild = cond([
        [() => importer && this.importer === importer, () => this.child],
        [
          () => importer,
          () =>
            loadable(() => this.props.importer(), {
              // todo: better loading indicator
              fallback: <></>,
            }),
        ],
        [stubTrue, () => false],
      ])();
      this.importer = importer;
      this.child = LoadableChild;
      return (
        <App>
          <Page
            product={product}
            noHeader={noHeader}
            disableAppMenu={disableAppMenu}
          >
            {LoadableChild && <LoadableChild {...this.props} />}
            {this.props.children}
          </Page>
        </App>
      );
    }

    const { product } = this.props;
    // only save non-dynamic product state to local storage
    if (product !== "dynamic") {
      window.localStorage.setItem("product", product);
    }
    // set product from local storage to mimic a persistent state
    if ((this.props = { ...this.props }).product === "dynamic") {
      this.props.product = window.localStorage.getItem("product") || "paper";
    }

    const isMobile = !!this.state.isMobile;
    const { disableAppMenu, headerContent, importer, ...more } = this.props;

    // Accept a loadable component using the `importer` prop.
    // Normal composition follows with `this.props.children`. See for example NotFoundPage.
    // We are imitating useMemo to preserve loadable between updates if we are
    // given the same importer
    const LoadableChild = cond([
      [() => importer && this.importer === importer, () => this.child],
      [
        () => importer,
        () =>
          loadable(() => this.props.importer(), {
            fallback: <LoadingSpinner />,
          }),
      ],
      [stubTrue, () => false],
    ])();
    this.importer = importer;
    this.child = LoadableChild;
    return (
      <ContainerQuery query={query}>
        {(params) => (
          <div className={classNames(params)}>
            <AntLayout {...more}>
              <LayoutState />
              {!disableAppMenu && <Menu {...this.props} isMobile={isMobile} />}
              <AntLayout>
                {!this.props.noHeader && (
                  <AntHeader>
                    {headerContent ? headerContent : <TopBar {...this.props} />}
                  </AntHeader>
                )}
                <AntContent style={{ height: "100%", padding: "2rem" }}>
                  {LoadableChild && <LoadableChild {...this.props} />}
                  {this.props.children}
                </AntContent>
              </AntLayout>
            </AntLayout>
          </div>
        )}
      </ContainerQuery>
    );
  }
}

export default Layout;
