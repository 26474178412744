import useRequest, { QueryOptions } from "./useRequest";

import { getPaperAccounts, PaperAccount } from "../rest/account";

export const useGetPaperAccounts = (
  key: any | any[] = "",
  accountID: string = "",
  options?: QueryOptions<PaperAccount[]>
) => {
  const { data: paper_accounts } = useRequest(
    key,
    () => getPaperAccounts(accountID),
    {
      enabled: !!accountID,
      ...options,
    }
  );

  return { paper_accounts: paper_accounts || [] };
};

export default useGetPaperAccounts;
