import instance from "..";

export interface Billing {
  lifetimeSubCount: number;
}

export const getBillingOverview = async (): Promise<Billing> => {
  const res = await instance.get("/billing/overview");

  return res.data;
};

export interface CryptoFeeStructure {
  fee_structure: {
    [key: string]: {
      volume: string;
      maker: string;
      taker: string;
    };
  };
}

export const getCryptoFeeStructure = async (): Promise<CryptoFeeStructure> => {
  const res = await instance.get("/crypto/fee-structure");
  return res.data;
};

export interface CryptoMonthlyVolume {
  volume: string;
}

export const getCryptoMonthlyVolume = async (
  accountID: string
): Promise<CryptoMonthlyVolume> => {
  const res = await instance.get(
    `/accounts/${accountID}/crypto/monthly-volume`
  );
  return res.data;
};
