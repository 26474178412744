import Fuse from "fuse.js";
import objpath from "object-path";

import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactEventHandler,
  useMemo,
  useState,
} from "react";

import { UseFormReturn } from "react-hook-form";
import { Box, Input, InputProps, Text } from "@chakra-ui/react";

type Fuzzable<T> = { original: T; text: any; value: any };

interface FuzzyProps<T> extends InputProps {
  name: string;
  items: (T & object)[];
  strict?: boolean;
  maxResults?: number;
  options?: Fuse.IFuseOptions<unknown>;
  onDidSelect?: (item: T) => void;
  hook: UseFormReturn<any, object>;
  accessors: {
    text: keyof T & string;
    value: keyof T & string;
    search: (keyof T & string)[];
  };
}

export const Fuzzy = <T,>({
  items,
  hook,
  strict,
  options,
  accessors,
  maxResults,
  onDidSelect,
  value,
  ...more
}: FuzzyProps<T>) => {
  const { name } = more;

  const [hide, setHide] = useState<boolean>(true);
  const [cursor, setCursor] = useState<number>(0);

  const fuzzable = items.map<Fuzzable<T>>((item) => ({
    original: item,
    text: item[accessors.text],
    value: objpath.get(item, accessors.value),
  }));

  const fuse = new Fuse(fuzzable, {
    threshold: 0,
    findAllMatches: true,
    ignoreLocation: true,
    keys: accessors.search.map((key) => "original.".concat(key)),
    ...options,
  });

  const matched = useMemo(
    () => fuse.search(String(value)).slice(0, maxResults || 10),
    [value, cursor]
  );

  // do nothing if value matches zero index text
  const onFocus: FocusEventHandler<HTMLInputElement> = ({ currentTarget }) =>
    setHide(matched.at(0)?.item.value === currentTarget.value);

  const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    // if value does not exist as text
    if (
      strict &&
      !fuzzable.find(
        ({ text, value }) =>
          text === event.currentTarget.value ||
          value === event.currentTarget.value
      )
    ) {
      // wipe value
      hook.setValue(name, "");
    }

    // trigger validation
    hook.trigger(name || "");

    // don't show matches on blur
    setHide(true);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // reset cursor
    setCursor(0);

    // show matched if more exist
    if (matched.length > 0) {
      setHide(false);
    }

    more.onChange && more.onChange(event);
  };

  const select: ReactEventHandler = () => {
    // out of bounds, do nothing
    if (cursor >= matched.length) {
      return;
    }

    const item = matched[cursor]?.item;

    hook.setValue(name, item.value);

    // call onDidSelect for parent
    onDidSelect && onDidSelect(matched[cursor].item.original);
    setHide(true);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case "Enter":
        select(event);
        event.preventDefault();
        break;
      case "ArrowUp":
        setCursor(Math.max(0, cursor - 1));
        break;
      case "ArrowDown":
        setCursor(Math.min(matched.length - 1, cursor + 1));
        break;
    }

    // trigger validation
    hook.trigger(name || "");
  };

  return (
    <Box>
      <Input
        {...more}
        value={hook.watch(name)}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {!hide && !!hook.watch(name)?.length && (
        <Box
          mt=".6rem"
          borderRadius="var(--chakra-radii-md)"
          position="absolute"
          zIndex="99999999"
          background="gray.15"
          overflow="hidden"
          w="full"
        >
          {matched.length > 0 ? (
            matched
              .map(({ item }) => item)
              .map((item, index) => (
                <Box
                  d="flex"
                  justifyContent="space-between"
                  px="1rem"
                  py=".59rem"
                  cursor="pointer"
                  bg={cursor === index ? "gray.15" : "transparent"}
                  onMouseDown={(event) => select(event)}
                  onMouseOver={() => setCursor(index)}
                >
                  <Text mb="0">{item.text}</Text>
                </Box>
              ))
          ) : (
            <Box
              d="flex"
              justifyContent="space-between"
              px="1rem"
              py=".59rem"
              cursor="pointer"
            >
              <Text mb="0">No results</Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Fuzzy;
