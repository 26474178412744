export const Path = {
  // routes
  ROUTE_LOGIN: "/account/login",
  ROUTE_DASHBOARD: "/:product/dashboard/overview",
  ROUTE_POSITIONS: "/positions",
  ROUTE_ORDERS: "/orders",
  ROUTE_TRADE: "/trade/:symbol",
  ROUTE_ACCOUNT_ACTIVITIES: "/:product/dashboard/account-activities",
  ROUTE_DOCUMENTS: "/account/documents",
  ROUTE_BALANCES: "/:product/dashboard/balances",
  ROUTE_CONFIGURATION: "/account/configuration",
  ROUTE_CRYPTO_TRANSFERS: "/:product/dashboard/crypto-transfers",
  ROUTE_BANKING: "/brokerage/banking",
  ROUTE_DEVELOPERS: "/developers",
  ROUTE_ALPACA_CONNECT: "/apps",
  ROUTE_ALPACA_CONNECT_SUBMIT: "/apps/submit",
  ROUTE_ALPACA_CONNECT_APP: "/apps/:client_id",
  ROUTE_ALPACA_PROFILE: "/user/profile#:tab",
  ROUTE_NEW_ACCOUNT: "/brokerage/new-account",
  ROUTE_BANKING_DEPOSIT: "/brokerage/banking?transfer=deposit",
  ROUTE_USER_SUBSCRIPTION: "/user/subscription",
  ROUTE_USER_SUBSCRIPTION_NEW: "/brokerage/new-account/data-subscription?upd=1",
  // urls
  URL_DOCS: "https://alpaca.markets/docs",
  URL_DOCS_ACCOUNT_ACTIVITIES: "https://docs.alpaca.markets/docs/account-activities",
  URL_SLACK:
    "https://join.slack.com/t/alpaca-community/shared_invite/zt-1z02tahfn-Q8Ma~lqazcDyJ~O9VrGIHg",
  URL_FORUM: "https://forum.alpaca.markets",
  URL_SUPPORT: "https://alpaca.markets/support",
  URL_DISCLOSURES: "https://alpaca.markets/disclosures",
  // helpers
  format: (path: string, params: { [key: string]: string }) =>
    Object.keys(params).reduce(
      (acc, key) =>
        acc.replace(`:${key}`, params[key].replace("live", "brokerage")),
      path
    ),
};
