import UI from "../src/local_libraries/@alpacahq:ui-deprecated";
import enGB from "antd/lib/locale-provider/en_GB";
import Layout from "./components/AlpacaComponents/Layout";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";
import AuthCheck from "./AuthCheck";
import CookieConsent from "react-cookie-consent";
import NotFoundPage from "./pages/NotFoundPage";
import RootRedirect from "./pages/RootRedirect";
import AmplitudeProvider from "./AmplitudeProvider";

import React, { useCallback } from "react";

import {
  checkLogin,
  requireCrytpoActive,
  requireLiveActive,
  requireLogin,
} from "./authMethods";

import { Link } from "./components/AlpacaComponents/Text";
import { store } from "./store";
import { history } from "./utils";
import { useFlag } from "@alpacahq/flag-service";
import { Provider } from "react-redux";
import { Location } from "history";
import { initAmplify } from "./reducers/auth/common";
import { ConfigProvider } from "antd";
import { locationTracker } from "./utils/eventTracker";
import { ConnectedRouter } from "connected-react-router";
import { init as initSentry } from "./Sentry";
import { init as initWebsocket } from "./websocket";
import { Redirect, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "resources/_antd.less";
import "resources/AntStyles/AntDesign/antd.override.scss";
import "resources/AlpacaStyles/alpaca.scss";
import { Toaster } from "react-hot-toast";

initSentry();

const AuthCheckRoute = (props: any) => {
  const component = useCallback(
    (routeProps) => (
      <AuthCheck
        routeProps={routeProps}
        redirector={props.redirector}
        component={props.component}
      />
    ),
    [props.redirector, props.component]
  );
  return <Route path={props.path} component={component} />;
};

// super gross but really easy way to disable our stupid debug logs in prod
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

initWebsocket();

const queryClient = new QueryClient();

history.listen((location: Location) => {
  locationTracker(location);
});

const render = () => {
  const NewAccountV2Importer = () =>
    import("./v2-deprecated/routes/dashboard/onboarding");

  const NewAccount = () => (
    <Layout
      product="paper"
      showSearchInHeader={true}
      noHeader={false}
      importer={NewAccountV2Importer}
    />
  );

  // check Amplify vs. Paciam (remove when full transition to Paciam happens)
  const hasCognitoCookies =
    document.cookie &&
    document.cookie
      .split(";")
      .filter((value) => value.match(/CognitoIdentityService.*/)).length > 0;
  if (hasCognitoCookies) {
    initAmplify();
  }

  ReactDOM.render(
    <AmplitudeProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={enGB}>
            <ConnectedRouter history={history}>
              <div>
                <Toaster />
                <CookieConsent
                  location="bottom"
                  buttonText="Accept"
                  cookieName="gdpr_accepted"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{
                    color: "#4e503b",
                    fontSize: "13px",
                    marginRight: "7em",
                  }}
                >
                  Alpaca and selected third parties use cookies on this website
                  as specified in the
                  <Link
                    href="https://files.alpaca.markets/disclosures/CookiePolicy.pdf"
                    style={{ color: "#fcd305" }}
                  >
                    cookie policy
                  </Link>
                  . You accept the use of these cookies or other identifiers by
                  pressing ‘Accept’, closing this notice, or logging into your
                  account.
                </CookieConsent>
                <Switch>
                  <Route
                    path="/account/login"
                    component={loadable(
                      () => import("./v2/pages/auth/AuthPage")
                    )}
                  />
                  <AuthCheckRoute
                    path="/exchange_questions"
                    component={loadable(
                      () => import("./pages/ExchangeQuestions")
                    )}
                  />
                  <AuthCheckRoute
                    path="/logout"
                    component={loadable(() => import("./pages/LogoutPage"))}
                    redirector={checkLogin}
                  />
                  <AuthCheckRoute
                    path="/signup"
                    component={loadable(
                      () => import("./v2-deprecated/routes/signup")
                    )}
                    redirector={checkLogin}
                  />
                  <AuthCheckRoute
                    path="/verify"
                    component={loadable(
                      () => import("./v2-deprecated/routes/signup")
                    )}
                    redirector={checkLogin}
                  />
                  <AuthCheckRoute
                    path="/signup/pending"
                    component={loadable(
                      () => import("./v2-deprecated/routes/signup")
                    )}
                    redirector={checkLogin}
                  />
                  <AuthCheckRoute
                    path="/signup/success"
                    component={loadable(
                      () => import("./v2-deprecated/routes/signup")
                    )}
                  />
                  <AuthCheckRoute
                    path="/onboarding/step/one"
                    component={loadable(
                      () =>
                        import("./v2-deprecated/routes/dashboard/onboarding")
                    )}
                  />
                  <AuthCheckRoute
                    path="/onboarding/step/two"
                    component={loadable(
                      () =>
                        import("./v2-deprecated/routes/dashboard/onboarding")
                    )}
                  />
                  <AuthCheckRoute
                    path="/onboarding/step/three"
                    component={loadable(
                      () =>
                        import("./v2-deprecated/routes/dashboard/onboarding")
                    )}
                  />
                  <AuthCheckRoute
                    path="/onboarding/kyc"
                    component={loadable(
                      () =>
                        import("./v2-deprecated/routes/dashboard/onboarding")
                    )}
                  />
                  <AuthCheckRoute
                    path="/onboarding/bank"
                    component={loadable(
                      () =>
                        import("./v2-deprecated/routes/dashboard/onboarding")
                    )}
                  />
                  <Route
                    path="/dashboard/portfolio"
                    component={() => (
                      <Redirect push to="/brokerage/dashboard/portfolio" />
                    )}
                  />
                  <Route
                    path="/login"
                    component={() => <Redirect push to="/account/login" />}
                  />
                  <Route
                    path="/brokerage/dashboard/portfolio"
                    render={() => <Redirect push to="/positions" />}
                  />
                  <Route
                    path="/paper/dashboard/portfolio"
                    render={() => <Redirect push to="/positions" />}
                  />
                  <AuthCheckRoute
                    path="/sign/W8-BEN"
                    component={loadable(
                      () => import("./v2-deprecated/routes/sign/W8-BEN")
                    )}
                  />
                  <Route
                    path="/mobile-redirect"
                    component={loadable(() => import("./pages/MobileRedirect"))}
                  />
                  <Route path="/.well-known/apple-app-site-association" />
                  {/* OAuth */}
                  <AuthCheckRoute
                    path="/oauth/authorize"
                    component={() => (
                      <Layout
                        noHeader={true}
                        disableAppMenu={true}
                        showBrandInHeader={true}
                        showSearchInHeader={false}
                        importer={() =>
                          import("./pages/OAuth/AuthorizationPage")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  {/* USER & ALPACA ACCOUNT ROUTES */}
                  <AuthCheckRoute
                    path="/user/edit-profile"
                    component={() => (
                      <Layout
                        disableAppMenu={true}
                        showBrandInHeader={true}
                        showSearchInHeader={false}
                        importer={() => import("./pages/User/EditProfilePage")}
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/user/profile"
                    component={() => (
                      <Layout
                        product="dynamic"
                        showSearchInHeader={true}
                        importer={() =>
                          useFlag("uix-v2")
                            ? import("./v2-deprecated/routes/dashboard/profile")
                            : import("./pages/User/ProfilePage")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/user/subscription"
                    component={() => (
                      <Layout
                        product="live"
                        showSearchInHeader={true}
                        importer={() => import("./pages/User/SubscriptionPage")}
                      />
                    )}
                    redirector={requireLogin}
                  />
                  {/* PAPER ROUTES */}
                  <AuthCheckRoute
                    path="/paper/dashboard/overview"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Overview"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/paper/dashboard/orders"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/PaperTrading/Orders"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/paper/dashboard/order/:orderId"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import("./pages/Dashboard/Product/PaperTrading/Order")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/paper/dashboard/balances"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/PaperTrading/Balances"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/paper/dashboard/account-activities"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import(
                                "./v2-deprecated/routes/dashboard/activities"
                              )
                            : import(
                                "./pages/Dashboard/Product/PaperTrading/AccountActivities"
                              )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/paper/dashboard/config"
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/PaperTrading/AccountConfig"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path={["/paper/trade/:symbol*", "/paper/pairs/:symbol*"]}
                    component={() => (
                      <Layout
                        product="paper"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/PaperTrading/StockPage"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path={[
                      "/brokerage/stocks/:symbol*",
                      "/brokerage/pairs/:symbol*",
                    ]}
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/StockPage"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  {/* BROKERAGE ROUTES */}
                  <AuthCheckRoute
                    path="/brokerage/signup"
                    component={() => (
                      <Layout
                        product="live"
                        showSearchInHeader={false}
                        importer={() => import("./pages/SignUpBrokeragePage")}
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/data-agreement/:agreement"
                    component={() => (
                      <Layout
                        product="live"
                        showSearchInHeader={false}
                        importer={() => import("./pages/DataAgreementPage")}
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/developers"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          import("./v2-deprecated/routes/dashboard/developers")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/new-account/:step"
                    component={NewAccount}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    exact
                    path="/brokerage/new-account"
                    component={NewAccount}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/overview"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Overview"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/balances"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Balances"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/positions"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import(
                                "./v2-deprecated/routes/dashboard/positions"
                              )
                            : import(
                                "./pages/Dashboard/Product/BrokerageAccount/Portfolio"
                              )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/orders"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import("./v2-deprecated/routes/dashboard/orders")
                            : import(
                                "./pages/Dashboard/Product/BrokerageAccount/Orders"
                              )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/account/configuration"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import(
                                "./v2-deprecated/routes/dashboard/account/configuration"
                              )
                            : import(
                                "./pages/Dashboard/Product/BrokerageAccount/AccountConfig"
                              )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/account/documents"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import(
                                "./v2-deprecated/routes/dashboard/account/documents"
                              )
                            : import(
                                "./pages/Dashboard/Product/BrokerageAccount/Documents"
                              )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/orders"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Orders"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/order/:orderId"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Order"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/balances"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Balances"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/config"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/AccountConfig"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/profile"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Account"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/documents"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Documents"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/document-requests"
                    component={() => (
                      <Layout
                        product="live"
                        showSearchInHeader={false}
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/DocumentRequests"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/banking"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Banking"
                          )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/account-activities"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import(
                                "./v2-deprecated/routes/dashboard/activities"
                              )
                            : import(
                                "./pages/Dashboard/Product/BrokerageAccount/AccountActivities"
                              )
                        }
                      />
                    )}
                    redirector={requireLiveActive}
                  />
                  <AuthCheckRoute
                    path="/brokerage/agreement"
                    component={() => (
                      <Layout
                        product="live"
                        showSearchInHeader={false}
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/Agreement"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <Route
                    path={["/trade/:symbol*", "/pairs/:symbol*"]}
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/StockPage"
                          )
                        }
                      />
                    )}
                  />
                  <AuthCheckRoute
                    path="/brokerage/apps/manage"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthAppGrid"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/apps/submit"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import("./pages/Dashboard/Product/Connect/SubmitApp")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/apps/edit/:clientId"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import("./pages/Dashboard/Product/Connect/SubmitApp")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/apps/:clientId"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import("./v2/pages/connect/AppDetailsPage")
                            : import("./pages/Dashboard/Product/Connect/App")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/apps"
                    component={() => (
                      <Layout
                        product="dynamic"
                        importer={() =>
                          useFlag("uix-v2")
                            ? import("./v2/pages/connect/AppPage")
                            : import("./pages/Dashboard/Product/Connect/Apps")
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/apps/edit/agreement"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthAppGrid/OauthAppAgreement"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/apps/edit/:clientId"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthContainer"
                          )
                        }
                      />
                    )}
                    redirector={requireLogin}
                  />
                  <AuthCheckRoute
                    path="/brokerage/dashboard/crypto-transfers"
                    component={() => (
                      <Layout
                        product="live"
                        importer={() =>
                          import(
                            "./pages/Dashboard/Product/BrokerageAccount/CoinTransfers"
                          )
                        }
                      />
                    )}
                    redirector={requireCrytpoActive}
                  />
                  {/* BUSINESS ROUTES */}
                  <AuthCheckRoute
                    path="/business"
                    component={loadable(() => import("./pages/Business"))}
                    redirector={requireLogin}
                  />
                  {/* CONTEST ROUTES */}
                  <AuthCheckRoute
                    path="/contest/:participantId"
                    component={loadable(
                      () => import("./pages/Contest/Participant")
                    )}
                  />
                  <AuthCheckRoute
                    path="/contest"
                    component={loadable(() => import("./pages/Contest"))}
                  />
                  {/* TODO: redirect based on account status */}
                  <AuthCheckRoute
                    path="/"
                    component={() => <RootRedirect />}
                    redirector={requireLogin}
                  />
                  {/* REDIRECTS */}
                  <Route
                    path="/account"
                    render={() => (
                      <Redirect push to="/brokerage/dashboard/profile" />
                    )}
                  />
                  <Route
                    path="/docs"
                    render={() => (
                      <Redirect push to="/brokerage/dashboard/documents" />
                    )}
                  />
                  <Route
                    path="/dashboard/overview"
                    render={() => (
                      <Redirect push to="/brokerage/dashboard/overview" />
                    )}
                  />
                  <Route
                    path="/dashboard/onboarding"
                    render={() => (
                      <Redirect push to="/brokerage/dashboard/onboarding" />
                    )}
                  />
                  <Route
                    path="/new-account"
                    render={() => <Redirect push to="/brokerage/new-account" />}
                  />
                  <Route
                    path="/brokerage/international/new-account/:step"
                    render={() => <Redirect to="/brokerage/new-account/" />}
                  />
                  {/* CATCH ALL, NOT FOUND - this creates a loop */}
                  <Route component={NotFoundPage} />
                </Switch>
              </div>
            </ConnectedRouter>
          </ConfigProvider>
        </QueryClientProvider>
      </Provider>
    </AmplitudeProvider>,
    document.getElementById("root")
  );
};

render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("components/AlpacaComponents/Layout", () => {
    render();
  });
}

export default history;
