import Auth from "@aws-amplify/auth";
import { createBrowserHistory } from "history";
import { getCookie } from "src/lib/cookies";
import CryptoJS from "crypto-js";
import { getPaciamJwt } from "src/v2-deprecated/api/auth/paciam";

export const history = createBrowserHistory();

export const push = history.push;
export const replace = history.replace;
export const goBack = history.goBack;

export const isUrl = (url) => {
  const protocol = "(?:(?:[a-z\\u00a1-\\uffff0-9\\-]+)://)";
  const ipv4 =
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))";
  const hostname = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
  const domain =
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
  const tld = "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))";
  const port = "(?::\\d{2,5})?";
  const resourcePath = "(?:[/?#]\\S*)?";
  const regex = `${protocol}(?:(?:localhost|${ipv4}|${hostname}(?:${domain}(?:${tld}\\.?))?)?${port}${resourcePath})?`;
  const isUrl = new RegExp(`^${regex}$`, "i");

  return isUrl.test(url);
};

export const weakEncrypt = (str) => {
  const encrypted = CryptoJS.AES.encrypt(str, window.env.SHARED_SECRET);
  return encrypted.toString();
};

export class UserNotAuthenticated extends Error {
  name = "UserNotAuthenticated";
  constructor() {
    super("The user is not authenticated");
  }
}

// will load from local storage so
// not guaranteed to return valid token
export const getCurrentAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    if (e === "The user is not authenticated") {
      throw new UserNotAuthenticated();
    }
    throw e;
  }
};

// will refresh token automatically
export const getSessionJWT = async () => {
  try {
    if (getCookie("paciamRefreshToken")) {
      const token = await getPaciamJwt();
      return token;
    }

    const session = await Auth.currentSession();
    const token = session.getIdToken();
    return token.getJwtToken();
  } catch (e) {
    throw new UserNotAuthenticated();
  }
};

export const parseUtmParams = (params) => {
  const utm_params = {};
  for (const utm_key of ["utm_source", "utm_medium", "utm_campaign"]) {
    if (params.has(utm_key)) {
      utm_params[utm_key] = params.get(utm_key);
    }
  }
  return utm_params;
};
