import React from "react";

import {
  BoxProps,
  Spinner as ChakraSpinner,
  SpinnerProps,
  Flex,
} from "@chakra-ui/react";

export const Spinner = ({
  size,
  thickness,
  ...more
}: BoxProps & SpinnerProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    h="100%"
    w="100%"
    zIndex={99999999}
    my="1rem"
    {...more}
  >
    <ChakraSpinner
      thickness={thickness || "4px"}
      speed="0.65s"
      emptyColor="gray.20"
      color="#f7c911"
      size={size || "xl"}
    />
  </Flex>
);

export default Spinner;
