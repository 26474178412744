import instance from '../'
import { ACHRelationship } from './relationships'

export const getPlaidLinkToken = async (accountID: string) => {
  const { data } = await instance.get(`/accounts/${accountID}/plaid/link/token`)
  return data
}

export const getPlaidAuthStatus = async (plaidAcconutID: string) => {
  const { data } = await instance.get<ACHRelationship>(
    `/accounts/${plaidAcconutID}/plaid/auth_status`
  )
  return data
}
