import instance from '../'
import { Account } from './account'

export const createOnfidoApplicant = async (accountID: string) => {
  const { data } = await instance.post<Account>(
    `/accounts/${accountID}/onfido/applicant`
  )
  return data
}

export const updateOnfidoApplicant = async (accountID: string) => {
  const { data } = await instance.patch(
    `/accounts/${accountID}/onfido/applicant`
  )
  return data
}

export const getOnfidoSdkToken = async (accountID: string) => {
  const { data } = await instance.get(`/accounts/${accountID}/onfido/sdk_token`)
  return data
}

type OnfidoSDKOutcomes = 'SDK_ERROR' | 'USER_COMPLETED'
export type PatchOnfidoParams = {
  accountID: string
  outcome: OnfidoSDKOutcomes
  token: string
}
export const patchOnfidoSdkToken = async ({
  accountID,
  ...rest
}: PatchOnfidoParams) => {
  const { data } = await instance.patch(
    `/accounts/${accountID}/onfido/sdk`,
    rest
  )
  return data
}
