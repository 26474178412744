import instance from "..";

export interface Asset {
  id: string;
  class: string;
  exchange: string;
  symbol: string;
  name: string;
  status: string;
  tradable: boolean;
  marginable: boolean;
  maintenance_margin_requirement: number;
  shortable: boolean;
  easy_to_borrow: boolean;
  fractionable: boolean;
  min_order_size: string;
  min_trade_increment: string;
  price_increment: string;
}

// fetch the asset list
export const getAssets = async (): Promise<Asset[]> => {
  const res = await instance.get<Asset[]>("/assets");
  return res.data;
};

// fetch the asset list
export const getAsset = async (symbol: string): Promise<Asset> => {
  const res = await instance.get<Asset>(`/assets/${symbol}`);
  return res.data;
};
