import useRequest, { QueryOptions } from "./useRequest";

import { QueryObserverResult } from "react-query";
import { Transfer, getRapydTransfers } from "../rest/banking";

type UseGetRapydTransfers = {
  rapydTransfers?: Transfer[];
  refetch: () => Promise<QueryObserverResult<Transfer[], unknown>>;
};

export const useGetRapydTransfers = (
  accountId: string,
  key: any | any[] = "",
  options?: QueryOptions<Transfer[]>
): UseGetRapydTransfers => {
  const { data: rapydTransfers, isError, isSuccess, refetch } = useRequest(
    "rapyd-transfers" + key,
    () => getRapydTransfers(accountId),
    {
      ...options,
      refetchInterval: false,
    }
  );

  return { rapydTransfers, refetch };
};
