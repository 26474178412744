import { getQuotes, Quote } from "../rest/data";
import useRequest, { QueryOptions } from "./useRequest";

export const useGetQuotes = (
  key: any | any[] = "",
  symbols: string[] = [],
  options?: QueryOptions<Quote[]>
) => {
  const { data: quotes, refetch, isFetched } = useRequest(
    key,
    () => getQuotes(symbols),
    { ...options, refetchInterval: false }
  );

  return { quotes, refetch, isFetched };
};

export default useGetQuotes;
