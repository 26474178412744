import React from "react";

import { Path } from "../../../path";
import { UIIcon } from "../../../../../src/local_libraries/@alpacahq:ui-deprecated";
import { Account } from "../../../api/rest/account";
import { isPaperOrOnboarding } from "../../../utils";

export interface PaperBannerProps {
  account?: Account;
}

export const PaperBanner = ({ account }: PaperBannerProps) => (
  <div className="border-b-3 border-blue-500 rounded-md border bg-blue-100 p-4 flex items-center mt-6 mx-8 xs:ml-0">
    <UIIcon name="InformationCircle" className="h-5 w-5 text-blue-600 mr-2" />
    <p className="text-base font-medium text-blue-600">
      <span>You are on Paper Trading, no real money is being used.</span>
      {account && !isPaperOrOnboarding(account) ? (
        <a
          className="font-semibold underline hover:underline ml-4"
          href={Path.format(Path.ROUTE_DASHBOARD, { product: "live" })}
        >
          Switch to Live Trading?
        </a>
      ) : null}
    </p>
  </div>
);
