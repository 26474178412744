import React from "react";

import { Box } from "@chakra-ui/react";

type AsteriskProps = {
  required?: boolean;
};

export const Asterisk = ({ required }: AsteriskProps) => (
  <Box color="red.40" display={required ? "inline-block" : "none"}>
    *
  </Box>
);

export default Asterisk;
