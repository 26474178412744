import { useRequest } from ".";
import { getOrders } from "../rest/account";

export const useGetOrders = (
  accountID: string,
  product: string,
  symbols?: string[],
  options?: any
) => {
  const { data, refetch } = useRequest(
    ["orders", accountID, symbols],
    () =>
      getOrders({
        accountID: accountID || "",
        symbols,
        paper: product === "paper",
      }),
    { ...(options || {}) }
  );
  return { orders: data || [], refetch };
};

export default useGetOrders;
