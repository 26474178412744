import local from "../../utils/countries.json";

import { useState } from "react";
import { useQuery } from "react-query";
import { getRestrictedCountries } from "../rest/onboarding";

type Country = {
  name: string;
  "alpha-3": string;
  "country-code": string;
};

export const useGetCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);

  useQuery("restricted-countries", getRestrictedCountries, {
    cacheTime: 0,
    onSuccess: (data) =>
      setCountries(local.filter((c) => !data.includes(c["alpha-3"]))),
  });

  return {
    countries,
    countriesRaw: local as Country[],
    alpha3ToName: (alpha3: string) =>
      local.find(({ ["alpha-3"]: short }) => short === alpha3)?.name,
    nameToAlpha3: (name: string) =>
      local.find(({ name: n }) => n === name)?.["alpha-3"],
  };
};

export default useGetCountries;
