import instance from "..";

export interface Quote {
  symbol: string;
  bid_timestamp: string;
  bid: number;
  bid_size: number;
  bid_exchange: string;
  ask_timestamp: string;
  ask: number;
  ask_size: number;
  ask_exchange: string;
  last_timestamp: string;
  last: number;
  last_size: number;
  last_exchange: string;
  volume: number;
  highest_price: number;
  lowest_price: number;
  last_day_closing: string;
}

export const getQuotes = async (symbols: string[]): Promise<Quote[]> => {
  const res = await instance.get(`/quotes?symbols=${symbols.join(",")}`);

  return res.data;
};

export interface Clock {
  timestamp: string;
  is_open: boolean;
  next_open: string;
  next_close: string;
}

export const getClock = async (): Promise<Clock> => {
  const res = await instance.get("/clock");
  return res.data;
};

// get orderbooks
export const getOrderbooks = async (symbols: string[]): Promise<any> => {
  const res = await instance.get(
    `/latest/orderbooks?symbols=${symbols.join(",")}`,
    {
      baseURL: window.env.CRYPTO_DATA_API_URL,
    }
  );
  return res.data;
};

/**
export const getTrades = async (symbols: string[]): Promise<any> => {
  const meow: any = [];
  const limit = 500;

  const next: any = async (token?: string) => {
    const { data } = await instance.get(
      `/trades?symbols=${symbols.join(",")}&limit=${limit}${
        !token ? `&start=${moment().subtract(1, "minutes").toISOString()}` : ""
      }${token ? `&page_token=${token}` : ""}`,
      {
        baseURL: window.env.CRYPTO_DATA_B2_API_URL,
      }
    );

    if (data.trades[symbols[0]]) {
      meow.push(...data.trades[symbols[0]]);
    }

    const next_page_token = data.next_page_token;

    return await next(next_page_token);
  };

  if (meow.length == 0) {
    await next();
  }

  console.log(meow);

  return meow;
};
 **/

export interface CryptoSnapshot {
  dailyBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
  latestQuote: {
    ap: number;
    as: number;
    bp: number;
    bs: number;
    t: string;
  };
  latestTrade: {
    i: number;
    p: number;
    s: number;
    t: string;
    tks: string;
  };
  minuteBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
  prevDailyBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
}

export interface CryptoSnapshots {
  snapshots: {
    [key: string]: CryptoSnapshot;
  };
}

export interface GetCryptoSnapshotParams {
  symbols: string[];
}

// get crypto snapshot
export const getCryptoSnapshots = async (
  params: GetCryptoSnapshotParams
): Promise<CryptoSnapshots> => {
  const res = await instance.get(
    `/snapshots?symbols=${params.symbols.join(",")}`,
    {
      baseURL: window.env.CRYPTO_DATA_API_URL,
    }
  );
  return res.data;
};

export interface CryptoAsset {
  id: string;
  class: string;
  exchange: string;
  symbol: string;
  name: string;
  status: string;
  tradable: boolean;
  marginable: boolean;
  maintenance_margin_requirement: number;
  shortable: boolean;
  easy_to_borrow: boolean;
  fractionable: boolean;
  min_order_size: string;
  min_trade_increment: string;
  price_increment: string;
}

// we have to use this for now; not exposed on staging
export const getCryptoAssets = async (): Promise<CryptoAsset[]> => {
  const res = await instance.get("/assets/public/beta", {
    baseURL: "https://api.alpaca.markets/v2",
  });
  return res.data || [];
};
