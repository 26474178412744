import axios from "axios";
import { getCookie, setCookie } from "src/lib/cookies";

const paciamInstance = axios.create({
  baseURL: window.env.PACIAM_URL,
});

const SECS_IN_DAY = 86400;

type PaciamSession = {
  access_token: string;
  refresh_token: string;
  refresh_expires_in: number;
  access_expires_in: number;
  account_source: string;
};

export const paciamLogin = async (
  username: string,
  password: string
): Promise<PaciamSession> => {
  const resp = await paciamInstance.post("/authenticate", {
    email: username,
    s: password,
  });
  const data: PaciamSession = resp.data;

  if (data.account_source === "" || data.account_source === "brokerdash") {
    return data;
  }

  parsePaciamSession(data);
  return data;
};

export const getPaciamJwt = async (): Promise<string> => {
  const paciamAccessToken = getCookie("paciamAccessToken");
  if (paciamAccessToken) return paciamAccessToken;

  const paciamRefreshToken = getCookie("paciamRefreshToken");

  const resp = await paciamInstance.post("/authenticate/refresh", {
    refresh_token: paciamRefreshToken,
  });
  const data: PaciamSession = resp.data;
  parsePaciamSession(data);

  return resp.data.access_token;
};

const parsePaciamSession = (sess: PaciamSession) => {
  const {
    access_token,
    access_expires_in,
    refresh_token,
    refresh_expires_in,
  } = sess;
  setCookie("paciamAccessToken", access_token, access_expires_in / SECS_IN_DAY);
  setCookie(
    "paciamRefreshToken",
    refresh_token,
    refresh_expires_in / SECS_IN_DAY
  );
};

export {};
