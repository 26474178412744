import ReactGA from "react-ga";

const trackingID = window.env.GOOGLE_ANALYTICS_ID;


if (trackingID) {
  ReactGA.initialize(trackingID);
}

export const locationTracker = (location) => {
  if (trackingID) {
    ReactGA.pageview(location.pathname);
  }
};

export const eventTracker = (action, data) => {
  if (trackingID && data) {
    ReactGA.event({
      action: action,
      category: data.category,
      label: data.label,
      value: data.value,
    });
  }
};